export const VALID_GUESSES = [
	'aisha',
	'akagi',
	'akane',
	'akari',
	'akemi',
	'akina',
	'akira',
	'akuma',
	'akumi',
	'alban',
	'albio',
	'alice',
	'almal',
	'amane',
	'araka',
	'arata',
	'arisa',
	'artia',
	'aruka',
	'asano',
	'asobi',
	'astel',
	'asuka',
	'asuma',
	'ayame',
	'ayamy',
	'azura',
	'azusa',
	'baelz',
	'bobon',
	'botan',
	'calli',
	'cammy',
	'chaos',
	'chiem',
	'chiho',
	'chima',
	'chloe',
	'choco',
	'civia',
	'coyoi',
	'delta',
	'derem',
	'diana',
	'dolly',
	'doris',
	'elira',
	'emiko',
	'evans',
	'fauna',
	'flare',
	'furen',
	'gurin',
	'gwelu',
	'haato',
	'hachi',
	'hakos',
	'hakui',
	'hayun',
	'heart',
	'hirai',
	'hisae',
	'hyona',
	'ibuki',
	'inori',
	'iroas',
	'iroha',
	'isora',
	'issei',
	'isumi',
	'izumi',
	'izuru',
	'jelly',
	'kaede',
	'kaeru',
	'kanae',
	'kanau',
	'kanie',
	'kanna',
	'kanon',
	'kaoru',
	'karon',
	'kasai',
	'kerin',
	'kiara',
	'kisei',
	'klein',
	'krone',
	'kuroe',
	'kuroi',
	'kyoka',
	'kyoko',
	'laila',
	'layla',
	'lemon',
	'leona',
	'luxia',
	'maemi',
	'meeza',
	'meiji',
	'meika',
	'mirei',
	'miria',
	'mocha',
	'mochi',
	'moira',
	'momoa',
	'moona',
	'mouse',
	'mousu',
	'mumei',
	'mysta',
	'ninja',
	'nixie',
	'ogami',
	'okayu',
	'okuri',
	'ollie',
	'omega',
	'orion',
	'otori',
	'patra',
	'petit',
	'petra',
	'pikao',
	'pochi',
	'polka',
	'purin',
	'qilin',
	'qilou',
	'raima',
	'raine',
	'raito',
	'rakai',
	'rania',
	'rasai',
	'raska',
	'ratna',
	'raven',
	'reika',
	'reiko',
	'reimu',
	'reina',
	'reine',
	'reiyu',
	'revna',
	'ribon',
	'rikka',
	'riksa',
	'rimmu',
	'ringo',
	'riona',
	'rocco',
	'saeki',
	'sakyu',
	'sango',
	'seffy',
	'seigi',
	'seiko',
	'seina',
	'seira',
	'seiya',
	'selen',
	'shiba',
	'shien',
	'shiki',
	'shimo',
	'shine',
	'shion',
	'shiro',
	'shizu',
	'shoto',
	'shura',
	'siska',
	'sonny',
	'susam',
	'taiga',
	'taiki',
	'temma',
	'tirol',
	'tomoe',
	'tonya',
	'touya',
	'tsugu',
	'umigo',
	'urara',
	'uruha',
	'usada',
	'usagi',
	'ushio',
	'usuwa',
	'utako',
	'utano',
	'uzuki',
	'yagoo',
	'youko',
	'yuika',
]
