
export type VTuber = {
	id: string,
	name: string,
	photo: string,
}

export const VTUBERS = {
    "akina": {
        "id": "UCNW1Ex0r6HsWRD4LCtPwvoQ",
        "name": "\u4e09\u679d\u660e\u90a3 / Saegusa Akina",
        "english_name": "Saegusa Akina",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRPx1tjN1hlw9cNU2fPZTSJEpQrtSBnuc6DLhJ2=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "333akina",
        "video_count": "563",
        "subscriber_count": "445000",
        "clip_count": 400,
        "top_topics": [
            "apex",
            "minecraft",
            "ark"
        ]
    },
    "baelz": {
        "id": "UCgmPnx-EEeOrZSg5Tiw7ZRQ",
        "name": "Hakos Baelz Ch. hololive-EN",
        "english_name": "Hakos Baelz",
        "type": "vtuber",
        "org": "Hololive",
        "group": "English (Council)",
        "photo": "https://yt3.ggpht.com/7gFSRIM3_DhczV8AYjeP4EaS0OL-u_xLvIh9JhG9zJhPYEfVwsoUOK61L6eBlLjnPHN-EDvytQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hakosbaelz",
        "video_count": "159",
        "subscriber_count": "494000",
        "clip_count": 2803,
        "top_topics": [
            "minecraft",
            "talk",
            "membersonly"
        ]
    },
    "ayumi": {
        "id": "UCq7r-3p8ZBNBD2iM694tqow",
        "twitter": "ayumioneesan",
        "name": "Ayumi",
        "photo": "https://pbs.twimg.com/profile_images/1464481024893939713/LxjNdhve_400x400.jpg"
    },
    "group": {
        "id": "UCJFZiqLMntJufDCHc6bQixg",
        "name": "hololive \u30db\u30ed\u30e9\u30a4\u30d6 - VTuber Group",
        "english_name": "Hololive VTuber Group",
        "type": "vtuber",
        "org": "Hololive",
        "group": "",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLShj3wK6CEmow693uwoMqS7yj09e3AvtdrIRsXHQw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hololivetv",
        "video_count": "379",
        "subscriber_count": "1640000",
        "clip_count": 3786,
        "top_topics": [
            "Original_Song",
            "singing",
            "announce"
        ]
    },
    "komae": {
        "id": "UCmVomBV_EV5WpXhlyqBXUww",
        "name": "\u72db\u6c5f\u64ab\u5b50 \u4e03\u5909\u5316\u5b66\u6240\u3010\u3051\u3082\u308a\u3075\u3011",
        "english_name": "Komae Nadeshiko",
        "type": "vtuber",
        "org": "KEMOMIMI REFLE",
        "group": null,
        "photo": "https://yt3.ggpht.com/LDebWz-vI38QN-ZLJEzEfqSqHauAZzHdOd9c6Etu6NxnepjO75GbOlu0MWjSPEGexQsmlyePHg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "komae_kemo",
        "video_count": "96",
        "subscriber_count": "38000",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "Subnautica",
            "Cooking_Simulator"
        ]
    },
    "yuuna": {
        "id": "UC8CKGSvp9bRfmtWyfV5L9Jw",
        "name": "Yuuna Ch. \u306b\u306b\u3086\u30fc\u306a \u3010Tsunderia\u3011",
        "english_name": "Nini Yuuna",
        "type": "vtuber",
        "org": "Tsunderia",
        "group": "Generation 1",
        "photo": "https://yt3.ggpht.com/K09NNfvEfBWfyNqMdg3kQEcteGYCr8sqOpupn0oSkmV7lzLCe8CnbgPH5pm1LS-Y_s7A4o8Lmg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "niniyuuna",
        "video_count": "80",
        "subscriber_count": "16600",
        "clip_count": null,
        "top_topics": [
            "singing",
            "Music_Cover",
            "asmr"
        ]
    },
    "choco": {
        "id": "UC1suqwovbL1kzsoaZgFZLKg",
        "name": "Choco Ch. \u7652\u6708\u3061\u3087\u3053",
        "english_name": "Yuzuki Choco",
        "type": "vtuber",
        "org": "Hololive",
        "group": "2nd Generation",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQn_VxZ1ApMgQahrkcTtSdSAr6Jpxi4eHQiMnIlsw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "yuzukichococh",
        "video_count": "415",
        "subscriber_count": "841000",
        "clip_count": 1888,
        "top_topics": [
            "dbd",
            "apex",
            "talk"
        ]
    },
    "shino": {
        "id": "UCFSkExeBcqI4nb_ArHeByNw",
        "name": "\u30b7\u30ce\u30e9\u30a4\u30e9 - Shino Laila\u3010WACTOR\u3011",
        "english_name": "Shino Laila",
        "type": "vtuber",
        "org": "WACTOR",
        "group": null,
        "photo": "https://yt3.ggpht.com/uw7MjV-nnXi57cg_nptGOn7MgA3S2Jq7NiQMJ1zwD6Kmolf33wKMv7vgvOP80ZUywik__qrrRQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "157",
        "subscriber_count": "121000",
        "clip_count": 70,
        "top_topics": [
            "singing",
            "apex",
            "talk"
        ]
    },
    "endou": {
        "id": "UCBURM8S4LH7cRZ0Clea9RDA",
        "name": "Reimu Endou\u3010NIJISANJI EN\u3011",
        "english_name": "Reimu Endou",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/VxzFz_Dvepexh6qF_j3-Sp1HsKz9u_t0YegWeQ4L_Vegz-BHMHSrpIo8BAE46Rv4AwsYDK_-Hw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ReimuEndou",
        "video_count": "140",
        "subscriber_count": "153000",
        "clip_count": 298,
        "top_topics": [
            "apex",
            "membersonly",
            "talk"
        ]
    },
    "jaret": {
        "id": "UCccGnj6mw4RHQHI_2mKfjCQ",
        "name": "Jaret Fajrianto \u3010GHOSTY's COMIC\u3011",
        "english_name": "Jaret Fajrianto",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTyVbufpQgz9XwK27cQemZ9qqc0RIA_jHuek-cmng=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "jaretghosty",
        "video_count": "542",
        "subscriber_count": "76300",
        "clip_count": 6,
        "top_topics": [
            "genshin",
            "Music_Cover",
            "singing"
        ]
    },
    "amano": {
        "id": "UCajhBT4nMrg3DLS-bLL2RCg",
        "name": "\u5929\u91ce\u30d4\u30ab\u30df\u30a3. Pikamee",
        "english_name": "Amano Pikamee",
        "type": "vtuber",
        "org": "VOMS",
        "group": null,
        "photo": "https://yt3.ggpht.com/e2kt_pDVNvruI_hQILDm8pf_x8x1GXrM7Kp8LQWtXVMQjiK0PPxWA6oKT8V8b6fz5wmXsFM6Nw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "amanopikamee",
        "video_count": "646",
        "subscriber_count": "586000",
        "clip_count": 984,
        "top_topics": [
            "membersonly",
            "talk",
            "minecraft"
        ]
    },
    "lilpa": {
        "id": "UC-oCJP9t47v7-DmsnmXV38Q",
        "name": "\ub9b4\ud30c lilpa",
        "english_name": "lilpa",
        "type": "vtuber",
        "org": null,
        "group": null,
        "photo": "https://yt3.ggpht.com/ZFF_hEJhjNyF3UJLolZZPEV8EMM7V-e8HtTvzLiZXNM6s4rh518242ghR-bUXRYkMaJtedKoaZA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "167",
        "subscriber_count": "102000",
        "clip_count": null,
        "top_topics": [
            "shorts",
            "Music_Cover",
            "minecraft"
        ]
    },
    "usagi": {
        "id": "UC0u_-3zgLkSYpQOxlBi-5Ng",
        "name": "USAGIHIME CLUB.\u5154\u59ec",
        "english_name": "Usagi Hime",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/pMxh3VGo3FUvGQxkahRcHJV3LOa5CIfjac_mkV8Y8h2WFnF35XgHeexfibo5GYwy6lPrXCjhGA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kuroxusagihime",
        "video_count": "266",
        "subscriber_count": "44000",
        "clip_count": 7,
        "top_topics": [
            "Marshmallow",
            "talk",
            "singing"
        ]
    },
    "cocoa": {
        "id": "UC5Xse6X7vB_nUL5HvEFVo_g",
        "name": "\u9053\u660e\u5bfa\u3053\u3053\u3042 / COCOA DOMYOJI",
        "english_name": "Cocoa Domyoji",
        "type": "vtuber",
        "org": "Riot Music",
        "group": null,
        "photo": "https://yt3.ggpht.com/Ui6LceajZfcAmNxypxKynqLVTIlartes7JjgrICaiM3jFqyV4vR0Lh6HIZjri-bZrJLE40a37w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "cocoa_domyoji",
        "video_count": "114",
        "subscriber_count": "233000",
        "clip_count": 1,
        "top_topics": [
            "Music_Cover",
            "singing",
            "talk"
        ]
    },
    "patra": {
        "id": "UCeLzT-7b2PBcunJplmWtoDg",
        "name": "Patra Channel / \u5468\u9632\u30d1\u30c8\u30e9 \u3010\u30cf\u30cb\u30b9\u30c8\u3011",
        "english_name": "Suou Patra",
        "type": "vtuber",
        "org": "774inc",
        "group": "HoneyStrap",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSYUSatGTQH95P1P_9VbcSk55-gs_zOStiKA9qU=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Patra_HNST",
        "video_count": "1533",
        "subscriber_count": "564000",
        "clip_count": 175,
        "top_topics": [
            "asmr",
            "talk",
            "minecraft"
        ]
    },
    "tetra": {
        "id": "UC5u-VD8k8i8xneMtnWet_9Q",
        "name": "\u30c6\u30c8\u30e9channel",
        "english_name": "Tetra",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT9KOEEVf64nfoOZvVJKWWY7KBLBsmVYJjAHDCTLg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Tetra_VTuber",
        "video_count": "39",
        "subscriber_count": "13600",
        "clip_count": 6,
        "top_topics": [
            "minecraft",
            "announce",
            "celebration"
        ]
    },
    "izumi": {
        "id": "UCW8WKciBixmaqaGqrlTITRQ",
        "name": "Izumi Channel / \u67da\u539f\u3044\u3065\u307f \u3010\u3042\u306b\u307e\u30fc\u308c\u3011",
        "english_name": "Yunohara Izumi",
        "type": "vtuber",
        "org": "774inc",
        "group": "AniMare",
        "photo": "https://yt3.ggpht.com/A-rY6YFCLjO_EwxF5BDyjrkxoJByAF6HFEWvN2-ps-5JRkkybIPIS8X6shgNo3DSsmD9ixsaRA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Izumi_Yunohara",
        "video_count": "837",
        "subscriber_count": "74200",
        "clip_count": 23,
        "top_topics": [
            "minecraft",
            "Yu-Gi-Oh!",
            "Pokemon"
        ]
    },
    "rieru": {
        "id": "UCE5rWcDxLPaaUFWOxzJFfNg",
        "name": "\u5869\u5929\u4f7f\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Shiotenshi Rieru",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/w0rDvplG_s0vdP7OnMBpp9hm_e5i_tBEGlhWPPY0iVDiD0EZJD1V987SrUhHk8RZ2RMcpFMs=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "VAngelf_Riel",
        "video_count": "39",
        "subscriber_count": "15000",
        "clip_count": 4,
        "top_topics": [
            "apex",
            "Pokemon",
            "singing"
        ]
    },
    "tesla": {
        "id": "UCfhzPS_rZHHJ3AEQyIVm_gA",
        "name": "Tesla ch. / \u611b\u4e43\u3066\u3059\u3089",
        "english_name": "Itoshino Tesla",
        "type": "vtuber",
        "org": "Masquerade",
        "group": null,
        "photo": "https://yt3.ggpht.com/w43w9Pp49F5FL3Et9lhdZLudd2FGcO8Bf9bPmQvsIGWIrAZK9VcybJh3_95axtHtBKiu29zatw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ltoshinoTesla",
        "video_count": "106",
        "subscriber_count": "17000",
        "clip_count": null,
        "top_topics": [
            "talk",
            "little_nightmares",
            "minecraft"
        ]
    },
    "zouba": {
        "id": "UCElr8slMrY-Cr764_QIJHPA",
        "name": "\u96d1\u8449\u5b66\u7f8e - Zouba Channel",
        "english_name": "Zouba Manabi",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTy-fHsZiwWR8t4fpyeObLKNCKEW2gEaUlZD_A1jw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "zoubamanabi",
        "video_count": "380",
        "subscriber_count": "38200",
        "clip_count": null,
        "top_topics": [
            "shorts",
            "Pokemon",
            "talk"
        ]
    },
    "meiji": {
        "id": "UCveZ9Ic1VtcXbsyaBgxPMvg",
        "name": "\u7ae5\u7530\u660e\u6cbb-\u308f\u3089\u3079\u3060\u3081\u3044\u3058\u30fc-",
        "english_name": "Warabeda Meiji",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQzwCx2PaRbcZSGXDCZUfeHVfpZHCKGYZNI_0Uv=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "warabeda_meiji",
        "video_count": "316",
        "subscriber_count": "240000",
        "clip_count": 57,
        "top_topics": [
            "singing",
            "Final_fantasy",
            "asmr"
        ]
    },
    "ratio": {
        "id": "UCWQGiZK_rAMpnl4awiUrqqg",
        "name": "\u30e9\u30c6\u30a3\u30aa\u30fb\u30e6\u30a3\u30ea\u30b9\u3010Project:YuiRes\u3011",
        "english_name": "Ratio Yuires",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/U7_W3Hjl4qGpWUBeY_jhbj5-uKXALQPODOaFY46TnV3gfwtmuA6kUQgP9NmPZPBlmcR4uIDLDA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Ratio_Yuires",
        "video_count": "197",
        "subscriber_count": "23600",
        "clip_count": null,
        "top_topics": [
            "shorts",
            "singing",
            "Music_Cover"
        ]
    },
    "lotte": {
        "id": "UCxEyuO_ilEJ9P5BIbhASIew",
        "name": "Yaxie Lotte",
        "english_name": "Yaxie Lotte",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/BUzWeAU5R2VgZRddFmsJHqtbr_UvE-MAzpzJv4i3fzzEkQJvAD35xx5HIEp4G6IZNo6NdJNhq6E=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "YaxieLotte",
        "video_count": "32",
        "subscriber_count": "36100",
        "clip_count": 7,
        "top_topics": [
            "minecraft",
            "Pokemon",
            "shorts"
        ]
    },
    "furen": {
        "id": "UCuep1JCrMvSxOGgGhBfJuYw",
        "name": "\u30d5\u30ec\u30f3\u30fbE\u30fb\u30eb\u30b9\u30bf\u30ea\u30aa",
        "english_name": "Furen E Lustario",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2020",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTbqZA5R2hAgz0sIuRhGyUsJJ3cGvBEou4rR0Y=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "furen_2434",
        "video_count": "580",
        "subscriber_count": "425000",
        "clip_count": 717,
        "top_topics": [
            "minecraft",
            "talk",
            "Pokemon"
        ]
    },
    "asuka": {
        "id": "UCiSRx1a2k-0tOg-fs6gAolQ",
        "name": "\u98db\u9ce5\u3072\u306a\u3010\u306b\u3058\u3055\u3093\u3058\u6240\u5c5e\u3011",
        "english_name": "Asuka Hina",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "SEEDS",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR0UWVzVKPszUqvRS7xjShmcAcnhcZwg71lVVPodw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hina__asuka",
        "video_count": "206",
        "subscriber_count": "103000",
        "clip_count": 17,
        "top_topics": [
            "talk",
            "asmr",
            "Animal_Crossing"
        ]
    },
    "bunka": {
        "id": "UCM9B5B7NjnO7z6uPXixMa7w",
        "name": "\u6587\u5316\u653e\u9001V&R",
        "english_name": "Bunka Housou V&R",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTKeSdzDT53YXZTocBkhPZ6uzH3bZnwIqR8Z4YX=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "561",
        "subscriber_count": "63200",
        "clip_count": 149,
        "top_topics": [
            "singing",
            "PIEN",
            "Emily_wants_to_play"
        ]
    },
    "comic": {
        "id": "UCJ-a35UYtoSg5Y8kIj0YRAg",
        "name": "\u307f\u306b\u307e\u308b\u30b3\u30df\u30c3\u30af",
        "english_name": "Minimaru Comic",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/TvAveeLc8oti8L28uzTcn2KVZ8ovNlakW0eFH3Nru_oEOxOKUYEHKUnLpPnBx8mLppDfPmE39Q=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "minimaru",
        "video_count": "379",
        "subscriber_count": "50000",
        "clip_count": null,
        "top_topics": [
            "drawing",
            "residentevil",
            "celebration"
        ]
    },
    "utako": {
        "id": "UCwokZsOK_uEre70XayaFnzA",
        "name": "\u9234\u9e7f\u8a69\u5b50 Utako Suzuka",
        "english_name": "Suzuka Utako",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2",
        "photo": "https://yt3.ggpht.com/ZdU0mQTwpOUTW6COr5JZjBb_VLTBShB-yDvR3v0eMz1_MQrQiMYyCk5QYFgM8SJ2lAaRortAHNo=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "suzukautako",
        "video_count": "706",
        "subscriber_count": "514000",
        "clip_count": 216,
        "top_topics": [
            "talk",
            "singing",
            "Music_Cover"
        ]
    },
    "azura": {
        "id": "UCk5r533QVMgJUdWwqegH2TA",
        "name": "Azura Cecillia \u3010NIJISANJI ID\u3011",
        "english_name": "Azura Cecillia",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji ID",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLREm2qoo8RlDjLNB1W6kImyN0m4Mj5mwkDuV-eu=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Azura_Cecillia",
        "video_count": "392",
        "subscriber_count": "44200",
        "clip_count": 115,
        "top_topics": [
            "Final_fantasy",
            "genshin",
            "minecraft"
        ]
    },
    "amino": {
        "id": "UCDpajB_XSyvpBmAJqkwTMzQ",
        "name": "\u7db2\u91ce\u3074\u3053\u3093 / Pikon ch.",
        "english_name": "Amino Pikon",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/aBNQcayHrtnuSxMgVJCjzZUiF-66pgVuMKlrB2lq-kxDSpSPTBw7uMl6mAt9WDFuGDBM1Sh4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Piiiikoooon8",
        "video_count": "197",
        "subscriber_count": "31100",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "apex",
            "Detroit:_Become_Human"
        ]
    },
    "miona": {
        "id": "UCoAL7HKxjpPGuA1G4bdfHTQ",
        "name": "\u7687 \u7f8e\u7dd2\u5948 / MIONA SUMERAGI",
        "english_name": "Miona Sumeragi",
        "type": "vtuber",
        "org": "Riot Music",
        "group": null,
        "photo": "https://yt3.ggpht.com/KaZdNPlhd94o1KNP8fBS68m3nigY2sYEYzQRr39yVZjVdyvvJa-avrqkJrnsPBfVojCz9zCbYQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "miona_s_RIOT",
        "video_count": "7",
        "subscriber_count": "18800",
        "clip_count": null,
        "top_topics": [
            "Music_Cover",
            "Original_Song"
        ]
    },
    "souya": {
        "id": "UC2kyQhzGOB-JPgcQX9OMgEw",
        "name": "Ichika Channel / \u5b97\u8c37 \u3044\u3061\u304b \u3010\u3042\u306b\u307e\u30fc\u308c\u3011",
        "english_name": "Souya Ichika",
        "type": "vtuber",
        "org": "774inc",
        "group": "AniMare",
        "photo": "https://yt3.ggpht.com/Ugui7bmoHS1aQHh1ph_FgBMFvK27pdG6z-sONZ-xkpzKNY5B545FGnWbZHFBBfyBMYHJg4kQrg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Ichika_Souya",
        "video_count": "653",
        "subscriber_count": "92200",
        "clip_count": 47,
        "top_topics": [
            "singing",
            "talk",
            "minecraft"
        ]
    },
    "etora": {
        "id": "UCwY13RnqWK4FNFeHGrK96Gw",
        "name": "-ETORA-",
        "english_name": "Eto Etora",
        "type": "vtuber",
        "org": "V Dimension.Creators",
        "group": null,
        "photo": "https://yt3.ggpht.com/QMIpkuTYgSSzOoonDmqVQSK8fXztC1cCUkNJrOJ1WbF2zzb0zhdTuWsE_jITQvOQ2SONIswZ0g=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "eto_etora",
        "video_count": "134",
        "subscriber_count": "850",
        "clip_count": 17,
        "top_topics": [
            "talk",
            "Mahjong",
            "sakuna"
        ]
    },
    "elena": {
        "id": "UCKwATdrcjyzNv9c56PqJbWA",
        "name": "\u52c7\u51ea\u30a8\u30ec\u30ca / Elena Yunagi",
        "english_name": "Yunagi Elena",
        "type": "vtuber",
        "org": "ViViD",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQ4zIObzAHYkvLiP-4EnGccIX7wtHQk0sjKCNuPcQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ELENA_YUNAGI",
        "video_count": "916",
        "subscriber_count": "17500",
        "clip_count": 9,
        "top_topics": [
            "talk",
            "genshin",
            "Animal_Crossing"
        ]
    },
    "inaba": {
        "id": "UC0Owc36U9lOyi9Gx9Ic-4qg",
        "name": "Haneru Channel / \u56e0\u5e61\u306f\u306d\u308b \u3010\u3042\u306b\u307e\u30fc\u308c\u3011",
        "english_name": "Inaba Haneru",
        "type": "vtuber",
        "org": "774inc",
        "group": "AniMare",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS2sdJrp1tXcGrYbEmie_IGHILqMjQF8dVrV7BZ2A=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Haneru_Inaba",
        "video_count": "1934",
        "subscriber_count": "312000",
        "clip_count": 299,
        "top_topics": [
            "minecraft",
            "Mahjong",
            "talk"
        ]
    },
    "krone": {
        "id": "UC8oPnditPSp5lZu45fnXWCA",
        "name": "\u30a2\u30af\u30b7\u30a2\u30fb\u30af\u30ed\u30fc\u30cd / Axia Krone \u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Axia Krone",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2021",
        "photo": "https://yt3.ggpht.com/TZGu8o5n8ElCiK-TFu-e0tyEIm_gGzyZMTcpfa2vYLPiK0nUsal7fm7xpWGqZtcbLHFRhv4e=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "@AXIA_96NE",
        "video_count": "191",
        "subscriber_count": "224000",
        "clip_count": 82,
        "top_topics": [
            "Pokemon",
            "apex",
            "genshin"
        ]
    },
    "mochi": {
        "id": "UCBLqkkSpOi6a95Lf4BsTh7Q",
        "name": "MochiKomame Ch.\u3082\u3061\u3053\u307e\u3081 ",
        "english_name": "Mochi Komame",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/vyO8bl3PyG-6kc_CpEZHcTB1sV1lQlHd0OZhpTLB4pPyhvmhk4JfoT9seE9iTRBPxs7sf8hf=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "liquoricedrop",
        "video_count": "114",
        "subscriber_count": "164000",
        "clip_count": 143,
        "top_topics": [
            "singing",
            "talk",
            "celebration"
        ]
    },
    "gundo": {
        "id": "UCeShTCVgZyq2lsBW9QwIJcw",
        "name": "\u30103\u5e740\u7d44\u3011\u90e1\u9053\u7f8e\u73b2\u306e\u6559\u5ba4",
        "english_name": "Gundo Mirei",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSdNE-T9cYM194hv2Xfs7jI-mpjsWH2aDOKOr-4tQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "g9v9g_mirei",
        "video_count": "1071",
        "subscriber_count": "399000",
        "clip_count": 414,
        "top_topics": [
            "Mahjong",
            "apex",
            "genshin"
        ]
    },
    "wiffy": {
        "id": "UCzMAP-oh5-pC8j6RlCPf26w",
        "name": "\uc704\ud53c / Wiffy \u3010NIJISANJI KR\u3011",
        "english_name": "Wiffy",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT8mHyA-Kz_bucYqJ4ft8PBEkuvD3gIeW1d9IqD=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Wiffy2434",
        "video_count": "14",
        "subscriber_count": "8540",
        "clip_count": 1,
        "top_topics": [
            "minecraft",
            "singing",
            "dbd"
        ]
    },
    "sango": {
        "id": "UCL_O_HXgLJx3Auteer0n0pA",
        "name": "\u5468\u592e \u30b5\u30f3\u30b4 / Suo Sango\u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Suo Sango",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2020",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT8jX2XU6VpM_4AG2Bi9FsW-Xe1uyNVsXzbGewx=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Suo_Sango",
        "video_count": "357",
        "subscriber_count": "282000",
        "clip_count": 94,
        "top_topics": [
            "Superchat_Reading",
            "talk",
            "Pokemon"
        ]
    },
    "ayame": {
        "id": "UC7fk0CB07ly8oSl0aqKkqFg",
        "name": "Nakiri Ayame Ch. \u767e\u9b3c\u3042\u3084\u3081",
        "english_name": "Nakiri Ayame",
        "type": "vtuber",
        "org": "Hololive",
        "group": "2nd Generation",
        "photo": "https://yt3.ggpht.com/XDGhKwPZcT16Ppg2gQmLHEIYRhw9sY4rqG0HWbeCH68LHkhlVQrrFgxd5hWUuMb2nLfDOhu6=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "nakiriayame",
        "video_count": "203",
        "subscriber_count": "1240000",
        "clip_count": 3412,
        "top_topics": [
            "singing",
            "apex",
            "minecraft"
        ]
    },
    "riksa": {
        "id": "UCkL9OLKjIQbKk2CztbpOCFg",
        "name": "Riksa Dhirendra\u3010NIJISANJI ID\u3011",
        "english_name": "Riksa Dhirendra",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji ID",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT-HQY8Qb278GmEOdCstkc3iwi-1dpDp5V9_L4a=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "RiksaDhirendra",
        "video_count": "509",
        "subscriber_count": "45500",
        "clip_count": 47,
        "top_topics": [
            "minecraft",
            "genshin",
            "talk"
        ]
    },
    "white": {
        "id": "UC3M6qo4pfbnqDJGEREpf-RA",
        "name": "\u30a2\u30ea\u30b9\u30fb\u30db\u30ef\u30a4\u30c8alice white",
        "english_name": "Alice White",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR6j52frJ3gOy68mbF6pSzVNkaGlp0Nq0pCPbE8=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "alice_white_V",
        "video_count": "0",
        "subscriber_count": "1590",
        "clip_count": null,
        "top_topics": [
            "talk",
            "dbd",
            "fallguys"
        ]
    },
    "izuru": {
        "id": "UCZgOv3YDEs-ZnZWDYVwJdmA",
        "name": "Izuru Ch. \u594f\u624b\u30a4\u30c5\u30eb",
        "english_name": "Kanade Izuru",
        "type": "vtuber",
        "org": "Hololive",
        "group": "Holostars 1st Gen",
        "photo": "https://yt3.ggpht.com/dlq5H2SBaaAq9ilOfgGm0QvcVGEieSICsdk0Hv6oADZFn5Q0ctV1nxNAiQAJiS8u_svx5YlasQE=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kanadeizuru",
        "video_count": "360",
        "subscriber_count": "172000",
        "clip_count": 217,
        "top_topics": [
            "singing",
            "apex",
            "talk"
        ]
    },
    "akira": {
        "id": "UCcpgDsHDYHO1UyfpwjyNx1g",
        "name": "Kiichan Ch. \u72d0\u4e95\u30a2\u30ad\u30e9",
        "english_name": "Kitsui Akira",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR18_Sqym8DKuP0l99IewmI-1X57FifKquhLJpvdQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "KitsuiAkira",
        "video_count": "313",
        "subscriber_count": "46200",
        "clip_count": 2,
        "top_topics": [
            "Music_Cover",
            "singing",
            "League_of_Legends"
        ]
    },
    "honma": {
        "id": "UC0g1AE0DOjBYnLhkgoRWN1w",
        "name": "\u672c\u9593\u3072\u307e\u308f\u308a - Himawari Honma -",
        "english_name": "Honma Himawari",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Gamers",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQFPb_KIoPk1KxGXzAG71u4rKIUFiEzYRN_xeHm=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "honmahimawari",
        "video_count": "1146",
        "subscriber_count": "688000",
        "clip_count": 845,
        "top_topics": [
            "minecraft",
            "Pokemon",
            "ark"
        ]
    },
    "lovey": {
        "id": "UC6GKE9PDS2XvxRQO9nDikAQ",
        "name": "Lovey Ch. \u6714\u9b54\u30e9\u30f4\u30a3\u3010\u30b9\u30ca\u30c3\u30af\u306b\u308a\u3011",
        "english_name": "Sakuma Lovey",
        "type": "vtuber",
        "org": "Snacknili",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTqxqJvcc8aMFxXxnDQeqN07FJutit9KQ2gg0XJ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Sakuma_Lovey",
        "video_count": "215",
        "subscriber_count": "21100",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "fingger",
            "ringfit"
        ]
    },
    "karon": {
        "id": "UCINqoksO3CQPCt8a-mRe-Ew",
        "name": "\u8336\u51b7Karon-VirtuaReal",
        "english_name": "Karon",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "VirtuaReal (CN)",
        "photo": "https://yt3.ggpht.com/Syz98DWDsGww7fN44G7vRmddvLLVJ96c5S0UbWrDKnvrZOMv2wBWEXE39we3bb86xTFFqh20cA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Karon_vtuber",
        "video_count": "9",
        "subscriber_count": "3590",
        "clip_count": null,
        "top_topics": [
            "drawing",
            "singing",
            "untitled_goose_game"
        ]
    },
    "night": {
        "id": "UCQRfUPNla7dB-kdcWD92lJg",
        "name": "Mare Ch. \u306a\u3043\u3068\u3081\u3042-\u8033\u8210\u3081\u304a\u3070\u3051-",
        "english_name": "Night Mare",
        "type": "vtuber",
        "org": "Sleep Monster",
        "group": null,
        "photo": "https://yt3.ggpht.com/EbxngFUmWDNMYNuRuttWkjGAc6njvxFshPwOxC53crcsf7bn0rl3cR7Hx5zpHYxa43-E8lNBSg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Mare_ASMR",
        "video_count": "62",
        "subscriber_count": "200000",
        "clip_count": 2,
        "top_topics": [
            "asmr",
            "Pokemon"
        ]
    },
    "minai": {
        "id": "UCD1QOCJIAPsMKMvRSXjLahw",
        "name": "Aruma Ch. \u85ac\u888b\u30a2\u30eb\u30de",
        "english_name": "Minai Aruma",
        "type": "vtuber",
        "org": "GuildCQ",
        "group": null,
        "photo": "https://yt3.ggpht.com/RBP6XuW15gdNt2iivDcAfdV2VYP95D8eUHA8a0Apfevx4zJu_uFmsK6KxpyaJcHXC3GUxdxA9so=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "MinaiAlma",
        "video_count": "48",
        "subscriber_count": "3310",
        "clip_count": null,
        "top_topics": [
            "talk",
            "singing",
            "Hollow_Knight"
        ]
    },
    "evans": {
        "id": "UCqjTqdVlvIipZXIKeCkHKUA",
        "name": "\u30aa\u30ea\u30d0\u30fc\u30fb\u30a8\u30d0\u30f3\u30b9 / Oliver Evans \u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Oliver Evans",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2021",
        "photo": "https://yt3.ggpht.com/H0CnxuCVqCtqcWq7i9LmkmHCXKopCpwD6TFtIsGsY3bLXf1_jmmsWM3gskGyu--2JVbHWPGURA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "@oliverD_23",
        "video_count": "160",
        "subscriber_count": "191000",
        "clip_count": 140,
        "top_topics": [
            "Superchat_Reading",
            "Ace_Attorney",
            "Pokemon"
        ]
    },
    "shiba": {
        "id": "UCmeyo5pRj_6PXG-CsGUuWWg",
        "name": "\u9ed2\u4e95\u3057\u3070\u3010\u306b\u3058\u3055\u3093\u3058\u306e\u72ac\u3011",
        "english_name": "Kuroi Shiba",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "SEEDS",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRKf9p1esL7DeVqMWITAVJXQknx0jrC9m91Sljl=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "BlackShiba_chan",
        "video_count": "389",
        "subscriber_count": "184000",
        "clip_count": 49,
        "top_topics": [
            "Final_fantasy",
            "minecraft",
            "talk"
        ]
    },
    "usada": {
        "id": "UC1DCedRgGHBdm81E1llLhOQ",
        "name": "Pekora Ch. \u514e\u7530\u307a\u3053\u3089",
        "english_name": "Usada Pekora",
        "type": "vtuber",
        "org": "Hololive",
        "group": "3rd Generation",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSmHTeNNQp8A4AwsUPKzBa2ubDBWe6RSaG39mAYTw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "usadapekora",
        "video_count": "868",
        "subscriber_count": "1890000",
        "clip_count": 15844,
        "top_topics": [
            "minecraft",
            "Dragon_Quest",
            "ark"
        ]
    },
    "toiki": {
        "id": "UCNj88lUpk9fyzQydjbsuzRw",
        "name": "Toiki Ch. \u591c\u60f3\u3068\u3044\u304d",
        "english_name": "Toiki Ch. \u591c\u60f3\u3068\u3044\u304d",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/bCURl8GuSPFhHKJOv2ZJTv6Rvar6aArna715SmIAWemHmiZ9wEp4dgB3NIeAdiiX5kBGacKa=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "yaso_toiki",
        "video_count": "135",
        "subscriber_count": "21200",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "talk",
            "singing"
        ]
    },
    "kerin": {
        "id": "UCeAfiVvEuyICYJW-f3GnQjQ",
        "name": "\u30b1\u30ea\u30f3",
        "english_name": "Kerin",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQ4-N7lNoKGyfYUBHCoWVeoHvgQQ3KhSQGOzl8fVw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Kerin_Vtuber",
        "video_count": "123",
        "subscriber_count": "157000",
        "clip_count": null,
        "top_topics": [
            "apex",
            "minecraft",
            "Music_Cover"
        ]
    },
    "ivory": {
        "id": "UCczJwsnH2Ldf8j68mPSTRhg",
        "name": "Ivory Lotafia",
        "photo": "https://pbs.twimg.com/profile_images/1492921626647830529/GKQ2knJg_400x400.jpg"
    },
    "ifeta": {
        "id": "UCXSbl3XQYtx1u4Gvvca7NUA",
        "name": "Lily Ifeta Ch.\u300e Re:Memories \u300f",
        "english_name": "Lily Ifeta",
        "type": "vtuber",
        "org": "ReMemories",
        "group": null,
        "photo": "https://yt3.ggpht.com/Yf6LCzG8H2RZtHBHwNr6dMcBHabrmDW_fhmxW7o2Qc7m-tKLJk5YkGOuwnRzakaiW9g7_X8hZA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "LilyIfeta",
        "video_count": "251",
        "subscriber_count": "20600",
        "clip_count": 32,
        "top_topics": [
            "singing",
            "talk",
            "minecraft"
        ]
    },
    "erika": {
        "id": "UC9rwQW3jUK6Xb3aOgqnhyzg",
        "name": "Erika",
        "photo": "https://pbs.twimg.com/profile_images/1111958973203124224/XQMGKgGE_400x400.jpg"
    },
    "kaoru": {
        "id": "UCsehvfwaWF6nWuFnXI0AqZQ",
        "name": "Kaoru Ch.\u6708\u4e0b\u30ab\u30aa\u30eb",
        "english_name": "Tsukishita Kaoru",
        "type": "vtuber",
        "org": "Hololive",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQrY9o5NRMtXpH3FJAqDHAZUAaLSQF30rG39h61=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "0",
        "subscriber_count": "39900",
        "clip_count": 11,
        "top_topics": [
            "talk",
            "minecraft",
            "Mahjong"
        ]
    },
    "ollie": {
        "id": "UCYz_5n-uDuChHtLo7My1HnQ",
        "name": "Kureiji Ollie Ch. hololive-ID",
        "english_name": "Kureiji Ollie",
        "type": "vtuber",
        "org": "Hololive",
        "group": "Indonesia 2nd Gen",
        "photo": "https://yt3.ggpht.com/jWxru6sHDDSuKF-gztFg_WSoMp2da_d019iH0xz0MDWc7TIhetK8id_mVKV0PxWKp-QS23AzfQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kureijiollie",
        "video_count": "416",
        "subscriber_count": "982000",
        "clip_count": 4182,
        "top_topics": [
            "apex",
            "minecraft",
            "talk"
        ]
    },
    "senba": {
        "id": "UC7WY5VP-P-rSb2ES5npk8yA",
        "name": "\u5343\u7fbd \u9ed2\u4e43:Senba Channel",
        "english_name": "Senba Kurono",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQi9NYiRN_z0PbgH0aHvwPKADN5vIkay4FV535X=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "senba_crow",
        "video_count": "404",
        "subscriber_count": "87300",
        "clip_count": 3,
        "top_topics": [
            "Mahjong",
            "talk",
            "apex"
        ]
    },
    "tsuki": {
        "id": "UC0O0-Th0plo8YADhx71eO5g",
        "name": "Kuuki Tsuki \u3010Virtunix\u3011",
        "english_name": "Kuuki Tsuki",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/UgJzz2-VL4TamHnVgLak7tapI0PCqe2b-NokyRVUhOz4RCWS3ZojwCcumeYcl5xuYa-k4cyt=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kuukivt",
        "video_count": "34",
        "subscriber_count": "43000",
        "clip_count": 17,
        "top_topics": [
            "singing",
            "minecraft",
            "Birthday"
        ]
    },
    "chloe": {
        "id": "UCIBY1ollUsauvVi4hW4cumw",
        "name": "Chloe ch. \u6c99\u82b1\u53c9\u30af\u30ed\u30f1 - holoX -",
        "english_name": "Sakamata Chloe",
        "type": "vtuber",
        "org": "Hololive",
        "group": "6th Generation (HoloX)",
        "photo": "https://yt3.ggpht.com/_xNtPLKQcQxMTnOr4tAcGz0GEeESsi0bQX9mJEP3fx_50a8GUfUyOG1eyLIld2cCz6GvKABwpQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "sakamatachloe",
        "video_count": "82",
        "subscriber_count": "772000",
        "clip_count": 1919,
        "top_topics": [
            "Superchat_Reading",
            "singing",
            "talk"
        ]
    },
    "ayamy": {
        "id": "UCr9p1ZjLKgfaoqNorY7PiWQ",
        "name": "ayamy \u3042\u3084\u307f\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Ayamy",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR2V-3izHXJKoioDDGlL13f_M804kh3PYGkUdjv=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ayamy_garubinu",
        "video_count": "377",
        "subscriber_count": "56700",
        "clip_count": 14,
        "top_topics": [
            "drawing",
            "asmr",
            "talk"
        ]
    },
    "botan": {
        "id": "UCUKD-uaobj9jiqB-VXt71mA",
        "name": "Botan Ch.\u7345\u767d\u307c\u305f\u3093",
        "english_name": "Shishiro Botan",
        "type": "vtuber",
        "org": "Hololive",
        "group": "5th Generation",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQXr6MeUpHI0-yNZIAaGXHvBVowhCWMwGx-zXYs=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "shishirobotan",
        "video_count": "537",
        "subscriber_count": "1100000",
        "clip_count": 6279,
        "top_topics": [
            "minecraft",
            "Call_of_Duty",
            "battlefield"
        ]
    },
    "pairo": {
        "id": "UCx45gypxj0fME6zxsLKsNow",
        "name": "\u7114\u6a5f\u30d1\u30a4\u30ed\u3010\u307b\u3080\u3089\u304e\u30d1\u30a4\u30ed\u3011",
        "english_name": "Homuragi Pairo",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS5iQA4-CZ4zmY3IxNIjJ0OTm8GGNG8EADk-cmfPg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "memento_vivi",
        "video_count": "277",
        "subscriber_count": "3770",
        "clip_count": 3,
        "top_topics": [
            "Granblue_Fantasy",
            "Pokemon",
            "Terraria"
        ]
    },
    "cielo": {
        "id": "UCFGc2HPJyNXCa61ipu2Tnbw",
        "name": "\u5929\u7460\u7483\u3061\u3047\u308d / Lapis Cielo",
        "english_name": "Lapis Cielo",
        "type": "vtuber",
        "org": "Independents",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSWC8jZ8qgmY0FFHuJm4vvKmL2ti6i3ohXX1mXt=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Lapis_Cielo",
        "video_count": "547",
        "subscriber_count": "42500",
        "clip_count": null,
        "top_topics": [
            "among_us",
            "smash",
            "singing"
        ]
    },
    "rulia": {
        "id": "UCobENw9ZqZb1dpu6pT_8-9g",
        "name": "Rulia Hoshina Ch. \u661f\u90a3\u30eb\u30ea\u30a2",
        "english_name": "Hoshina Rulia",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/YdsQgDNJ79rYysTvO3PnHvJF0xF1djxAgIuvYE6DWTJXo5bBAcza1msL96VTY_OCoAVHU4APPQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "RuliaHoshina",
        "video_count": "154",
        "subscriber_count": "47700",
        "clip_count": 2,
        "top_topics": [
            "asmr",
            "morning",
            "Dark_Souls"
        ]
    },
    "leona": {
        "id": "UCB1s_IdO-r0nUkY2mXeti-A",
        "name": "\u7345\u5b50\u795e\u30ec\u30aa\u30ca/\u30ec\u30aa\u30ca\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Shishigami Leona",
        "type": "vtuber",
        "org": "ReAcT",
        "group": null,
        "photo": "https://yt3.ggpht.com/XdGacxrNaqlN68Qohc6qFvWZUqaOn3iiXK3bVWA1B4Zgb7LOaTnyC3gNcAGZDcvoUxCNScDE=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "LeonaShishigami",
        "video_count": "597",
        "subscriber_count": "272000",
        "clip_count": 116,
        "top_topics": [
            "singing",
            "apex",
            "Music_Cover"
        ]
    },
    "okayu": {
        "id": "UCvaTdHTWBGv3MKj3KVqJVCw",
        "name": "Okayu Ch. \u732b\u53c8\u304a\u304b\u3086",
        "english_name": "Nekomata Okayu",
        "type": "vtuber",
        "org": "Hololive",
        "group": "GAMERS",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT_TLZsRHyNXj_3v1QIfF5Z1LOEIKQPL_7HGH29=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "nekomataokayu",
        "video_count": "522",
        "subscriber_count": "1330000",
        "clip_count": 5313,
        "top_topics": [
            "talk",
            "Super Mario",
            "singing"
        ]
    },
    "namba": {
        "id": "UCSygy3LWcUZtFJ15TSbEskQ",
        "name": "\u96e3\u6ce2 \u3042\u304b\u308a / Namba Akari",
        "english_name": "Namba Akari",
        "type": "vtuber",
        "org": "MAHA5",
        "group": "JP \u5019\u88dc\u751f",
        "photo": "https://yt3.ggpht.com/bRgGr0-mluyhWu1TAcCkM_IEkyt-fCa9zuln9Yh8st4ChnLGdp_yaiM8QBpoEeMkw0Pw0s_7tmQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "namba_akari",
        "video_count": "78",
        "subscriber_count": "806",
        "clip_count": null,
        "top_topics": [
            "Mahjong",
            "minecraft",
            "apex"
        ]
    },
    "michi": {
        "id": "UClMEozXfSVOS3xcF2ZQJqfw",
        "name": "Michi Channel-\u305b\u3044\u304b\u307f\u3061-",
        "english_name": "Seika Michi",
        "type": "vtuber",
        "org": "Sleep Monster",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTQPoeSgwH4Bp7whqaCNpf8835tt7EcYM8JcpUA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Michi_ASMR",
        "video_count": "89",
        "subscriber_count": "20600",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "Papers_Please",
            "Anniversary"
        ]
    },
    "yaxie": {
        "id": "UCxEyuO_ilEJ9P5BIbhASIew",
        "name": "Yaxie Lotte",
        "english_name": "Yaxie Lotte",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/BUzWeAU5R2VgZRddFmsJHqtbr_UvE-MAzpzJv4i3fzzEkQJvAD35xx5HIEp4G6IZNo6NdJNhq6E=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "YaxieLotte",
        "video_count": "32",
        "subscriber_count": "36100",
        "clip_count": 7,
        "top_topics": [
            "minecraft",
            "Pokemon",
            "shorts"
        ]
    },
    "tsuyu": {
        "id": "UC59t349CdSDtqNS0hNzwBjQ",
        "name": "Tsuyu Hortenshia \u3018 Yume Reality \u3019",
        "english_name": "Tsuyu Hortenshia",
        "type": "vtuber",
        "org": "Yume Reality",
        "group": null,
        "photo": "https://yt3.ggpht.com/o6zqkbY5igUpM9bz-_zhWqgIZ0nO4C9FDsr3TvGjb-5b2sqMBLUyNqiwcs_pVO4OLlLmYToyNw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "TsuyuHortenshia",
        "video_count": "242",
        "subscriber_count": "38900",
        "clip_count": 30,
        "top_topics": [
            "singing",
            "talk",
            "apex"
        ]
    },
    "asahi": {
        "id": "UC2JWNoJJ8MRZkgINm2IhH7Q",
        "name": "\u671d\u967d\u306b\u3044\u306a / Nina Ch.",
        "english_name": "Asahi Nina",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRBqgiUkoIUxCvWOGVu99IkieiXOxmnuiBZDo9v=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "asahinina",
        "video_count": "776",
        "subscriber_count": "31400",
        "clip_count": null,
        "top_topics": [
            "Mahjong",
            "watchalong",
            "mariokart"
        ]
    },
    "oruka": {
        "id": "UCheqzZj54e5y_5HEtwG7FSA",
        "name": "\u30a2\u30af\u30ed\u30fc",
        "english_name": "Oruka",
        "type": "vtuber",
        "org": "V Dimension.Creators",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRF_ConWmQayZcnv2vWqEFShnBJsaE0xSbPrb0qhQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "\u30a2\u30af\u30ed\u30fc",
        "video_count": "11",
        "subscriber_count": "902",
        "clip_count": 6,
        "top_topics": [
            "drawing",
            "Mahjong",
            "apex"
        ]
    },
    "aisha": {
        "id": "UCqhhWjpw23dWhJ5rRwCCrMA",
        "name": "Aisha Channel",
        "english_name": "Aisha",
        "type": "vtuber",
        "org": "Polygon",
        "group": null,
        "photo": "https://yt3.ggpht.com/ek4O3kquLbMNTqmCwHaj4PYE8SkphVV9BK1-y_pbBQgkWqUJDPMO_-BYWlc5HwfEanYHEQVv=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Aisha_Channel",
        "video_count": "617",
        "subscriber_count": "418000",
        "clip_count": 5,
        "top_topics": [
            "Music_Cover",
            "shorts",
            "asmr"
        ]
    },
    "chamu": {
        "id": "UCa9wTL0z_iNN13n4G5VmbJg",
        "name": "\u3086\u3093\u3061\u3083\u3080\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Yun Chamu",
        "type": "vtuber",
        "org": "Marbl_s",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTcI1r_0u7qRZVFt8FfuRJVM4iBWX-1vcxzhS87=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "yuntyamutyamu",
        "video_count": "109",
        "subscriber_count": "1740",
        "clip_count": 16,
        "top_topics": [
            "talk",
            "Boku_no_Natsuyasumi",
            "Crayon_Shin-chan"
        ]
    },
    "tirol": {
        "id": "UCqskJ0nmw-_eweWfsKvbrzQ",
        "name": "Tirol Channel /\u6708\u91ce\u6728\u3061\u308d\u308b \u3010\u3042\u306b\u307e\u30fc\u308c\u3011",
        "english_name": "Tsukinoki Tirol",
        "type": "vtuber",
        "org": "774inc",
        "group": "AniMare",
        "photo": "https://yt3.ggpht.com/-fU4Dcwgiv8u8bJKLkjrWQYjpS6WDLOJ8k6Sbt9mJkgME8J8DBZUZX54yQ2RXufrDnShuxxVwnk=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "tirol0_0lorit",
        "video_count": "214",
        "subscriber_count": "47100",
        "clip_count": 2,
        "top_topics": [
            "singing",
            "Marshmallow",
            "asmr"
        ]
    },
    "hetto": {
        "id": "UC5bsyQW_9GfWa1d5Ed_gJNQ",
        "name": "Hetto",
        "english_name": "Hetto",
        "type": "vtuber",
        "org": "Twitch Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/y1V_Zp2nGvxelGmFbUlE9JKp2sItQvbEmf8s61I6f_skKB9KT5w93R8l6HrG8Ye6jIBow6q3rRI=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hettozu",
        "video_count": "129",
        "subscriber_count": "826000",
        "clip_count": null,
        "top_topics": [
            "minecraft",
            "asmr",
            "among_us"
        ]
    },
    "iroha": {
        "id": "UC_vMYWcDjmfdpH6r4TTn1MQ",
        "name": "Iroha ch. \u98a8\u771f\u3044\u308d\u306f - holoX -",
        "english_name": "Kazama Iroha",
        "type": "vtuber",
        "org": "Hololive",
        "group": "6th Generation (HoloX)",
        "photo": "https://yt3.ggpht.com/YK_UCAbw_pFBHSOw_LGWI-WCJDdvMP3y9mmODQ1IFEnNVvcf-M3-q22Db5TLWuAbfboMNFTMIg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kazamairohach",
        "video_count": "119",
        "subscriber_count": "511000",
        "clip_count": 1673,
        "top_topics": [
            "singing",
            "ringfit",
            "talk"
        ]
    },
    "kanau": {
        "id": "UC8jskpQfW9fn2NLK3PdaGdg",
        "name": "\u5922\u5ddd\u304b\u306a\u3046/Kanau ch",
        "english_name": "Yumekawa Kanau",
        "type": "vtuber",
        "org": "ReAcT",
        "group": null,
        "photo": "https://yt3.ggpht.com/s2ABVX7oiE7VMCN008UdXmjV26kELUEpVY0r5u93MNjj7BNpLhB5S6YvFRMJiiDsyiIfgWmF=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Kanau_Yumekawa",
        "video_count": "430",
        "subscriber_count": "25600",
        "clip_count": 58,
        "top_topics": [
            "singing",
            "minecraft",
            "apex"
        ]
    },
    "ieiri": {
        "id": "UC_hjHmi-ODGhHlSzD16p5Pw",
        "name": "Popo Channel / \u5bb6\u5165\u30dd\u30dd\u3010\u3072\u3088\u30af\u30ed\u3011",
        "english_name": "Popo Ieiri",
        "type": "vtuber",
        "org": "774inc",
        "group": "HIYOCRO",
        "photo": "https://yt3.ggpht.com/_wi_hVJaUoImdoNDfclroDNTDWT4boTVySpt-HGwYbXpjEdTtjgOWwqHv97EjNSSEGEIZtU4Yg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Popo_Ieiri",
        "video_count": "74",
        "subscriber_count": "5900",
        "clip_count": 1,
        "top_topics": [
            "minecraft",
            "Yu-Gi-Oh!",
            "singing"
        ]
    },
    "chiho": {
        "id": "UCJ6LH4jMNy0JN9RSThz1mMQ",
        "name": "\ud55c\uce58\ud638 / Chiho Han\u3010NIJISANJI KR\u3011",
        "english_name": "Chiho Han",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji KR",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRd7teJyS_Haq8JaINqNTaCLvfWgxAVycHAuZJkxg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ChihoHan2434",
        "video_count": "370",
        "subscriber_count": "43700",
        "clip_count": 23,
        "top_topics": [
            "talk",
            "dbd",
            "shorts"
        ]
    },
    "konan": {
        "id": "UC4PrHgUcAtOoj_LKmUL-uLQ",
        "name": "Mia Channel / \u6e56\u5357\u307f\u3042 \u3010\u3042\u306b\u307e\u30fc\u308c\u3011",
        "english_name": "Konan Mia",
        "type": "vtuber",
        "org": "774inc",
        "group": "AniMare",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQC3VwV4DAA_Le1VEShyGLk6UDQj_5IbYPoFmXY=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Mia_Konan",
        "video_count": "171",
        "subscriber_count": "24500",
        "clip_count": 1,
        "top_topics": [
            "talk",
            "singing",
            "asmr"
        ]
    },
    "amane": {
        "id": "UCZlDXzGoo7d44bwdNObFacg",
        "name": "Kanata Ch. \u5929\u97f3\u304b\u306a\u305f",
        "english_name": "Amane Kanata",
        "type": "vtuber",
        "org": "Hololive",
        "group": "4th Generation",
        "photo": "https://yt3.ggpht.com/TlH8nz5O9UYo5JZ_5fo4JfXdT18N0Ck27wWrulni-c1g5bwes0tVmFiSKICzI1SW7itaTkk9GA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "amanekanatach",
        "video_count": "818",
        "subscriber_count": "1160000",
        "clip_count": 7336,
        "top_topics": [
            "minecraft",
            "singing",
            "ark"
        ]
    },
    "riina": {
        "id": "UCtjQoCilYbnxUXquXcVU3uA",
        "name": "\u3070\u3093\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Bando Riina",
        "type": "vtuber",
        "org": "Marbl_s",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR1X5BSntTo98y5jgi2SpX6OtypLd7P9OxOv5XePA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Bando004",
        "video_count": "767",
        "subscriber_count": "3430",
        "clip_count": 2,
        "top_topics": [
            "Assassins_Creed",
            "talk",
            "Pokemon"
        ]
    },
    "shiki": {
        "id": "UCswvd6_YWmd6riRk-8oT-sA",
        "name": "Shiki Miyoshino / \u7f8e\u5409\u91ce\u3057\u304d [PRISM Project]",
        "english_name": "Shiki Miyoshino",
        "type": "vtuber",
        "org": "PRISM",
        "group": "Generation3",
        "photo": "https://yt3.ggpht.com/rsKUsvkZMZIGzOwYhXMEN8Ktovo_59vr5OYG6btna8iMFZ6zeBlmBMl3IN-CNF6mCjGdPJ5svnI=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ShikiMiyoshino",
        "video_count": "237",
        "subscriber_count": "116000",
        "clip_count": 45,
        "top_topics": [
            "talk",
            "Music_Cover",
            "minecraft"
        ]
    },
    "araka": {
        "id": "UCnYhIk9aGEx_bIgheVjs53w",
        "name": "Luto Araka / \u65b0\u706b\u308b\u3068 [PRISM Project]",
        "english_name": "Araka Luto",
        "type": "vtuber",
        "org": "PRISM",
        "group": "Generation2",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTw3xW4t-5ZPLE1TgDIFyHODyuFR34vz8Zy_gsE=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "LutoAraka",
        "video_count": "227",
        "subscriber_count": "65600",
        "clip_count": 154,
        "top_topics": [
            "minecraft",
            "singing",
            "shorts"
        ]
    },
    "usuwa": {
        "id": "UCeqIMtLuGc3YgwkhEaG8oDg",
        "name": "\u7a00\u7fbd\u3059\u3046 - Suu Usuwa -",
        "english_name": "Usuwa Suu",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRMRunPrpj7qR42AQntSltAEjigk38rp37WCcy3=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "usuwasuu",
        "video_count": "175",
        "subscriber_count": "26200",
        "clip_count": 2,
        "top_topics": [
            "singing",
            "talk",
            "Music_Cover"
        ]
    },
    "pochi": {
        "id": "UC22BVlBsZc6ta3Dqz75NU6Q",
        "name": "\u307d\u3061\u307e\u308b:POCHI-GOYA channel",
        "english_name": "Iida Pochi",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTQgr7qlDG11tgi1eCM1xawXiQD9eP2_dN3NOTQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "lizhi3",
        "video_count": "335",
        "subscriber_count": "203000",
        "clip_count": 76,
        "top_topics": [
            "drawing",
            "minecraft",
            "apex"
        ]
    },
    "ibuki": {
        "id": "UCZfQRuwSLty74QAj55BaKlA",
        "name": "Meno Ibuki / \u4f0a\u5439\u3081\u306e [PRISM Project]",
        "english_name": "Ibuki Meno",
        "type": "vtuber",
        "org": "PRISM",
        "group": "Generation1",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQIh0u6xUZOWWbNorUAdCX83lhDlUK1MnAj1IEh=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "IbukiMeno",
        "video_count": "407",
        "subscriber_count": "16400",
        "clip_count": 11,
        "top_topics": [
            "Princess_Connect",
            "minecraft",
            "asmr"
        ]
    },
    "kurea": {
        "id": "UCBQd84IW8OvM8H5jftHdvmw",
        "name": "\u795e\u57ce\u304f\u308c\u3042/Kurea Channel",
        "english_name": "Kamishiro Kurea",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/TR_3p0VJq-hgN7WRtuVUtrTlYFqqlYMfhHhNB61AOwg1PDVATnf-0X0drTnoF1QoyvjGjzxQ6A=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kamishiro_kurea",
        "video_count": "1080",
        "subscriber_count": "40100",
        "clip_count": 12,
        "top_topics": [
            "asmr",
            "minecraft",
            "monhun"
        ]
    },
    "haato": {
        "id": "UC1CfXB_kRs3C-zaeTG3oGyg",
        "name": "HAACHAMA Ch \u8d64\u4e95\u306f\u3042\u3068",
        "english_name": "Akai Haato",
        "type": "vtuber",
        "org": "Hololive",
        "group": "1st Generation",
        "photo": "https://yt3.ggpht.com/rNj6bichsOoUjA2N9iXWxInEt9Y2Fo5fhG4S8oR17ip8ouCu_7wmX3PnQxt6OP6Rd9OlYXYcmw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "akaihaato",
        "video_count": "730",
        "subscriber_count": "1340000",
        "clip_count": 4091,
        "top_topics": [
            "minecraft",
            "singing",
            "Tetris"
        ]
    },
    "ushio": {
        "id": "UCaum0e1MVZF69boluxgA0Bg",
        "name": "Ushio Ebi Ch.",
        "english_name": "Ushio Ebi",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTJWE-DAKUaR5LkXxxPz1KDO4Eknz62SR2sNvFm=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ushioebi",
        "video_count": "284",
        "subscriber_count": "45600",
        "clip_count": 2,
        "top_topics": [
            "minecraft",
            "genshin",
            "Honkai_Impact_3rd"
        ]
    },
    "ninja": {
        "id": "UCODNLyn3L83wEmC0DLL0cxA",
        "name": "\u671d\u30ce\u59c9\u59b9\u3077\u308d\u3058\u3047\u304f\u3068",
        "english_name": "Asano Ninja Sisters",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/tUjgQhGzqsQHR0hn4I9fCh1mkYsTzhlsaXRcfAI4w7BQyFxlRDVZbvx0qviDCEytQ2et83sA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "1275",
        "subscriber_count": "176000",
        "clip_count": 55,
        "top_topics": [
            "singing",
            "Music_Cover",
            "talk"
        ]
    },
    "okome": {
        "id": "UCIqfGSGcz_q7zkZYlmU04GQ",
        "name": "AMAKI OKOME CHANNEL ",
        "english_name": "Amaki Okome",
        "type": "vtuber",
        "org": "KAWAII MUSIC",
        "group": null,
        "photo": "https://yt3.ggpht.com/zE7Au3xUdRWemDR196KfYwfd1rbmqfEvKOf4uDbcataPBjMgwJS7ZaZuAYnlYsmoVxG2ChDDwA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "a_okome_channel",
        "video_count": "130",
        "subscriber_count": "40400",
        "clip_count": null,
        "top_topics": [
            "Pokemon",
            "Original_Song",
            "Music_Cover"
        ]
    },
    "refle": {
        "id": "UC4sjhgnEf2AQJRuxfWj30-Q",
        "name": "\u3010\u516c\u5f0f\u3011\u3051\u3082\u307f\u307f\u308a\u3075\u308c\u3063\uff01",
        "english_name": "Kemomimi Refle",
        "type": "vtuber",
        "org": "KEMOMIMI REFLE",
        "group": null,
        "photo": "https://yt3.ggpht.com/kzZWJ5oOqyVZJG2ojhxKhdbTjzXlh76upSVrdUjQz6yUcN1sttRtU1gDS4fsXiggJnIz_vDG=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kemo_refle",
        "video_count": "30",
        "subscriber_count": null,
        "clip_count": null,
        "top_topics": [
            "Music_Cover",
            "celebration",
            "clubhouse51"
        ]
    },
    "almal": {
        "id": "UCdpUojq0KWZCN9bxXnZwz5w",
        "name": "\u30a2\u30eb\u30b9\u30fb\u30a2\u30eb\u30de\u30eb -ars almal- \u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Ars Almal",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRHWKX5QF_YNsgxOMWvKbOuArKOoU1hgOtDrNHG=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ars_almal",
        "video_count": "804",
        "subscriber_count": "592000",
        "clip_count": 945,
        "top_topics": [
            "minecraft",
            "apex",
            "ark"
        ]
    },
    "reina": {
        "id": "UCNvi3ieS0g72WsXYlfVPrSA",
        "name": "Reina Official",
        "english_name": "Yuzuki Reina",
        "type": "vtuber",
        "org": "Emoechi",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTb5xuh4dNUU9JDTFqFbbrYRu4aght7kwDlweFXQA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "R_Yuzuki_",
        "video_count": "24",
        "subscriber_count": "187000",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "announce"
        ]
    },
    "pikon": {
        "id": "UCDpajB_XSyvpBmAJqkwTMzQ",
        "name": "\u7db2\u91ce\u3074\u3053\u3093 / Pikon ch.",
        "english_name": "Amino Pikon",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/aBNQcayHrtnuSxMgVJCjzZUiF-66pgVuMKlrB2lq-kxDSpSPTBw7uMl6mAt9WDFuGDBM1Sh4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Piiiikoooon8",
        "video_count": "197",
        "subscriber_count": "31100",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "apex",
            "Detroit:_Become_Human"
        ]
    },
    "azusa": {
        "id": "UCpNu3ngL8XHyQ0-T5I8dB2g",
        "name": "sakuya azusaCh.\u54b2\u591c\u3042\u305a\u3055 / \u30d7\u30ed\u30d7\u30ed",
        "english_name": "Sakuya Azusa",
        "type": "vtuber",
        "org": "ProPro",
        "group": null,
        "photo": "https://yt3.ggpht.com/IfgcrN8ioXQ8do91C4RFtpuuTXvB3lhn5NG8rGKYQ5G1sDQ9ZVGtzS21bLQ-lqbSQsZWQr8Scw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "sakuya_azusa",
        "video_count": "187",
        "subscriber_count": "18800",
        "clip_count": 5,
        "top_topics": [
            "singing",
            "fallguys",
            "Jump_King"
        ]
    },
    "gabri": {
        "id": "UCFvEuP2EDkvrgJpHI6-pyNw",
        "name": "\u304c\u3076\u308a\u3048\u308b-El Channel-",
        "english_name": "Gabri El",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTr3rFhE02HlwmUHOOmkVad7bUDScxSPmZuRXCs=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "gabri_elchan",
        "video_count": "17",
        "subscriber_count": "65900",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "residentevil",
            "talk"
        ]
    },
    "ribon": {
        "id": "UC6HjtF2rHZO8gAsX5FXF-Kg",
        "name": "Himekuma Ribon Ch. \u59eb\u718a \u308a\u307c\u3093",
        "english_name": "Himekuma Ribon",
        "type": "vtuber",
        "org": "ReAcT",
        "group": null,
        "photo": "https://yt3.ggpht.com/09d-QZ_t5ou4U4vcDjSaHYC7AZ-LsM17wSDicMRtk7xc6x5OBaT1mWO--CAU86UEh_lCx-ud1NU=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "sb_ribon_",
        "video_count": "1133",
        "subscriber_count": "97400",
        "clip_count": 658,
        "top_topics": [
            "asmr",
            "Gundam_Battle_Operation",
            "talk"
        ]
    },
    "otome": {
        "id": "UCvEX2UICvFAa_T6pqizC20g",
        "name": "\u4e59\u5973\u304a\u3068 / Otome Oto",
        "english_name": "Otome Oto",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/hj1_azOUaUlVUuDqUSSSJAWtsAKMR8t9LZgsl7LiS07P1fzMv_9A9kWAqXJtu17tgCWvtzO_=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "0tome0to",
        "video_count": "588",
        "subscriber_count": "42800",
        "clip_count": 6,
        "top_topics": [
            "singing",
            "ringfit",
            "talk"
        ]
    },
    "kiryu": {
        "id": "UCS9uQI-jC3DE0L4IpXyvr6w",
        "name": "Coco Ch. \u6850\u751f\u30b3\u30b3",
        "english_name": "Kiryu Coco",
        "type": "vtuber",
        "org": "Hololive",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQUA2htopp_nUXZScvJvh17wlOhSvcjAc-L75meCg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kiryucoco",
        "video_count": "788",
        "subscriber_count": "1440000",
        "clip_count": 5416,
        "top_topics": [
            "ark",
            "singing",
            "talk"
        ]
    },
    "usami": {
        "id": "UCvPlw6DtODOTwt76eaJg4Dw",
        "name": "\u514e\u4f50\u7f8e*Usami Channel",
        "english_name": "Usami Channel",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/8BP2wxSAghKcrtMwQGxpTt_DNF0DIHdyOudcqEW2lXbOsujZlQIM_qvEt7dghrAqfnqMdHyB=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "HP_usami",
        "video_count": "548",
        "subscriber_count": "23000",
        "clip_count": null,
        "top_topics": [
            "minecraft",
            "apex",
            "singing"
        ]
    },
    "izumo": {
        "id": "UCLpYMk5h1bq8_GAFVBgXhPQ",
        "name": "\u300aIzumoKasumi\u300bProject channel\u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Izumo Kasumi",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTaNfJr0bMx0XuO73kjtueX8krkjo-ONDVSuEj-LA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ikasumi_zzz",
        "video_count": "104",
        "subscriber_count": "113000",
        "clip_count": 6,
        "top_topics": [
            "Music_Cover",
            "celebration",
            "Outfit_Reveal"
        ]
    },
    "chris": {
        "id": "UC--A2dwZW7-M2kID0N6_lfA",
        "name": "Chris Channel / \u7345\u5b50\u738b\u30af\u30ea\u30b9 \u3010\u30b7\u30e5\u30ac\u30ea\u30ea\u3011",
        "english_name": "Shishio Chris",
        "type": "vtuber",
        "org": "774inc",
        "group": "SugarLyric",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQspWmEvmujEOiSlAjJRb6qagz8Q37Fsozz71CR=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ChrisShishio",
        "video_count": "437",
        "subscriber_count": "42600",
        "clip_count": 14,
        "top_topics": [
            "talk",
            "drawing",
            "singing"
        ]
    },
    "uruka": {
        "id": "UCjXJYPsKxoJyc-1RPB6dSyw",
        "name": "Uruka Ch. \u85e4\u5009\u30a6\u30eb\u30ab",
        "english_name": "Fujikura Uruka",
        "type": "vtuber",
        "org": "Phase Connect",
        "group": null,
        "photo": "https://yt3.ggpht.com/WNG8G6hOhsw-CqEMWF26QPUfuFcU74ALzydzTBuDB4TNQDOQWygmlDZnUytvdccH5ckrh95D=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "fujikurauruka",
        "video_count": "216",
        "subscriber_count": "31800",
        "clip_count": 23,
        "top_topics": [
            "singing",
            "Music_Cover",
            "minecraft"
        ]
    },
    "kevin": {
        "id": "UCAnKiHbZhEayttn6p-sxfbg",
        "name": "Kevin Vangardo\u3010MAHA5\u3011",
        "english_name": "Kevin Vangardo",
        "type": "vtuber",
        "org": "MAHA5",
        "group": null,
        "photo": "https://yt3.ggpht.com/nI-tReXVFanBo76i8Llr2usSl4e-KRccnr2T4XDPB0-deuFRKa7mFTZSrogch7PY2HOufEg4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "KevinVangardo",
        "video_count": "20",
        "subscriber_count": "22300",
        "clip_count": 6,
        "top_topics": [
            "Super Mario",
            "singing",
            "Roblox"
        ]
    },
    "chiyu": {
        "id": "UCBIR44irWpj1eTx0ZQFofHg",
        "name": "\u53e4\u5b88\u3061\u3086 /komori chiyu",
        "english_name": "Komori Chiyu",
        "type": "vtuber",
        "org": "Chukorara",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTHBOEGMQg7nmijt_nuH5GkDRgYqp-A9iiXRvPZ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "komori_chiyu",
        "video_count": "156",
        "subscriber_count": "12700",
        "clip_count": 1,
        "top_topics": [
            "singing",
            "valorant",
            "apex"
        ]
    },
    "hyona": {
        "id": "UCIBj1-d71vKjRftiauF50pg",
        "name": "Hyona Elatiora\u3010NIJISANJI ID\u3011",
        "english_name": "Hyona Elatiora",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji ID",
        "photo": "https://yt3.ggpht.com/wq0Twc5pH-PrX6pzwZJp3FFrli6ww-LVl0GZSlRQYrAYi8vdNik3sCZHMhZIL6W8OSCzCnlpbA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "HyonaElatiora",
        "video_count": "108",
        "subscriber_count": "24500",
        "clip_count": 64,
        "top_topics": [
            "talk",
            "Marshmallow",
            "Stardew_Valley"
        ]
    },
    "elira": {
        "id": "UCIeSUTOTkF9Hs7q3SGcO-Ow",
        "name": "Elira Pendora \u3010NIJISANJI EN\u3011",
        "english_name": "Elira Pendora",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/rBUqVoyIti32FfNnqy0cxMBZOLyvTmGoVjOqgMuvAjRdaEZR9EVAKQQ7atlIAJaCdmAgt_9OjlM=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "EliraPendora",
        "video_count": "244",
        "subscriber_count": "281000",
        "clip_count": 850,
        "top_topics": [
            "minecraft",
            "membersonly",
            "genshin"
        ]
    },
    "tammy": {
        "id": "UC7yqc24BjJwi3PoqhXrx6og",
        "name": "\u305f\u307f\u30fcCh / \u6c11\u5b89\u3068\u3082\u3048",
        "english_name": "Mia Tammy",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/E91Hll7YPhtg18uQxK1CJD-uC4psTrC-TpHMz3C41WmCWNJirukTyDZk1swm0RlIIm67K-rq=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "tammy_now",
        "video_count": "813",
        "subscriber_count": "120000",
        "clip_count": 9,
        "top_topics": [
            "shorts",
            "talk",
            "singing"
        ]
    },
    "neena": {
        "id": "UCtxY8FW7NsYLggrczfoI-1w",
        "name": "Neena Makurano Ch. \u6795\u306b\u3044\u306a\u3010kawaii\u3011",
        "english_name": "Neena Makurano",
        "type": "vtuber",
        "org": "Production Kawaii",
        "group": null,
        "photo": "https://yt3.ggpht.com/hoDRJ9e6umxgqsajj_PSXDr6Nu2oHbfbV7DQUvr0TgoWZWYdw1o1_qC3kIJWWtIdJ2eH-SMODA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Neena_Kawaii",
        "video_count": "185",
        "subscriber_count": "14300",
        "clip_count": 37,
        "top_topics": [
            "shorts",
            "apex",
            "Dying_Light"
        ]
    },
    "ebisu": {
        "id": "UCHyYlV44ZYCXtbzs60Zce1g",
        "name": "Touka ch. / \u6075\u6bd4\u5bff\u3068\u3046\u304b",
        "english_name": "Touka Ebisu",
        "type": "vtuber",
        "org": "Masquerade",
        "group": null,
        "photo": "https://yt3.ggpht.com/vP8sLOh2OreCeAZ2gVu47jAE7zAWu3-Nk_UN2SsB95Bz16EcXb4ckpkNZhDyWEU_x2wVln4iY8o=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Ebisu_Touka",
        "video_count": "17",
        "subscriber_count": "6500",
        "clip_count": null,
        "top_topics": [
            "apex",
            "fallguys",
            "Super_Bunny_Man"
        ]
    },
    "yuuki": {
        "id": "UCLO9QDxVL4bnvRRsz6K4bsQ",
        "name": "\u52c7\u6c17\u3061\u3072\u308d",
        "english_name": "Yuuki Chihiro",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 1",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTeATN14aTpJw1kpSdBUvcfiiHYVpNjGKnlmlc4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Chihiro_yuki23",
        "video_count": "1150",
        "subscriber_count": "592000",
        "clip_count": 966,
        "top_topics": [
            "apex",
            "minecraft",
            "The_Last_of_Us"
        ]
    },
    "kanda": {
        "id": "UCWz0CSYCxf4MhRKPDm220AQ",
        "name": "\u3010\u306b\u3058\u3055\u3093\u3058\u3011\u795e\u7530\u7b11\u4e00",
        "english_name": "Kanda Shoichi",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "SEEDS",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTI4ttkGOlmy7_ONt9zkWTbAIe7PbjaOpfmes-H=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Kanda_Shoichi",
        "video_count": "1380",
        "subscriber_count": "218000",
        "clip_count": 140,
        "top_topics": [
            "apex",
            "watchalong",
            "Final_fantasy"
        ]
    },
    "froot": {
        "id": "ApricottheLichVS",
        "name": "Apricot the Lich",
        "photo": "https://pbs.twimg.com/profile_images/1418143813856546819/AoPPdqdy_400x400.jpg"
    },
    "iwaka": {
        "id": "UC_9nmotxi7FAfiJpo1RKy8A",
        "name": "\u30de\u30fc\u30eb\u3044\u308f\u3063\u304b maaru",
        "english_name": "Maru Iwaka",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTEOvOvQSuk1Y2I9Ew6aDyyhe4KWo0w5UW2vpQk8w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "maaru_witch",
        "video_count": "0",
        "subscriber_count": "26400",
        "clip_count": 18,
        "top_topics": [
            "singing",
            "talk",
            "morning"
        ]
    },
    "kaida": {
        "id": "UCo2N7C-Z91waaR6lF3LL_jw",
        "name": "\u7532\u6590\u7530 \u6674 / Kaida Haru\u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Kaida Haru",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2020",
        "photo": "https://yt3.ggpht.com/PDk6FBRHOX2VIntWthp1XRRbKjjhQUVt6A1MDa8lsm-B4V6igcnsXHtGGFb-sQV7LnkqJNxnHQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Kaida_Haru",
        "video_count": "815",
        "subscriber_count": "381000",
        "clip_count": 320,
        "top_topics": [
            "minecraft",
            "singing",
            "apex"
        ]
    },
    "kanon": {
        "id": "UCQz5mRHdiwt7NrS9tc4D1Dg",
        "name": "\u9162\u9178\u304b\u306e\u3093",
        "english_name": "Sakusan Kanon",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/Qb0q2wiPQtdUjHC4okdadaxAAIJ2tbShKznMuGTt8fBDTSe6Q8Y155vlUxjii4_wAVaZ7ayg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Ts0___",
        "video_count": "149",
        "subscriber_count": "243000",
        "clip_count": 32,
        "top_topics": [
            "apex",
            "Knives_Out",
            "talk"
        ]
    },
    "takao": {
        "id": "UCM2SwvNjA0uH8oc5D0ihSDA",
        "name": "\u9ad8\u751f\u7d33\u58eb",
        "english_name": "Takao Shinji",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTKwFfmY9LWev5Q_rDkmOhFT6fhJh9NoQX6pA2Vtw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "takao_shinji",
        "video_count": "281",
        "subscriber_count": "152000",
        "clip_count": null,
        "top_topics": [
            "TRPG",
            "Music_Cover",
            "arknights"
        ]
    },
    "vault": {
        "id": "UCT6MjzHBJzerWNxQeJ0lNZg",
        "name": "Pikamee VOD \u30a2\u30fc\u30ab\u30a4\u30d6\u4fdd\u7ba1\u5eab",
        "english_name": "Pikamee VOD Archive Vault",
        "type": "vtuber",
        "org": "VOMS",
        "group": null,
        "photo": "https://yt3.ggpht.com/FWsdcwLNSLh4LsFE0zpRrpMvrgU_ybdcCFONz_PExm0plfSWpBjuSxBhjAInP9Dzx7nrtmE2=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "amanopikamee",
        "video_count": "7",
        "subscriber_count": "2340",
        "clip_count": 2,
        "top_topics": [
            "Heavy_Rain",
            "Pummel_Party"
        ]
    },
    "astel": {
        "id": "UCNVEsYbiZjH5QLmGeSgTSzg",
        "name": "astel ch.\u30a2\u30b9\u30c6\u30eb",
        "english_name": "Astel Leda",
        "type": "vtuber",
        "org": "Hololive",
        "group": "Holostars 2nd Gen",
        "photo": "https://yt3.ggpht.com/sr2RTZaWAFF20tSTiXUXp-BYRqWz4dZhFKbCzFS2dneTN5uSE53sSDjePN5dIM_sEz2U9-2qtRs=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "astelleda",
        "video_count": "536",
        "subscriber_count": "185000",
        "clip_count": 597,
        "top_topics": [
            "apex",
            "singing",
            "minecraft"
        ]
    },
    "ceann": {
        "id": "UCxN7pUZzXmMOuost2oRCHUw",
        "name": "Orla Ch. \u3010Tsunderia\u3011",
        "english_name": "Orla Gan Ceann",
        "type": "vtuber",
        "org": "Tsunderia",
        "group": "Generation 2",
        "photo": "https://yt3.ggpht.com/zN9XG3dNlbor22BYl24QRDqeZ-kSlqG8_HW_PvDvqyBJTPxdlDvhOKVcQ-OeyR_UXdiq4EkuXQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "orlaganceann",
        "video_count": "109",
        "subscriber_count": "3630",
        "clip_count": 2,
        "top_topics": [
            "dbd",
            "minecraft",
            "Bayonetta"
        ]
    },
    "omori": {
        "id": "UCrlV1Iy0clxpfBADND4ONIQ",
        "name": "Kira Omori VTuber",
        "english_name": "Kira Omori",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSzIiLpEgv__hStukPEynSNNpYOmiVCyXu6gwVw3w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "KiraOmori",
        "video_count": "39",
        "subscriber_count": "60500",
        "clip_count": 2,
        "top_topics": [
            "drawing",
            "english_only",
            "genshin"
        ]
    },
    "seika": {
        "id": "UClMEozXfSVOS3xcF2ZQJqfw",
        "name": "Michi Channel-\u305b\u3044\u304b\u307f\u3061-",
        "english_name": "Seika Michi",
        "type": "vtuber",
        "org": "Sleep Monster",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTQPoeSgwH4Bp7whqaCNpf8835tt7EcYM8JcpUA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Michi_ASMR",
        "video_count": "89",
        "subscriber_count": "20600",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "Papers_Please",
            "Anniversary"
        ]
    },
    "shiro": {
        "id": "UCLhUvJ_wO9hOvv_yYENu4fQ",
        "name": "Siro Channel",
        "english_name": "Dennou Shojo Shiro",
        "type": "vtuber",
        "org": ".LIVE",
        "group": null,
        "photo": "https://yt3.ggpht.com/T1zQF2H4KDqOpYdqoGtcMlWeZ_qpmvGTp1zNA-dQ-tQJxzxp0choIM9mr7dNB-LLaCev5_pGGE0=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "SIROyoutuber",
        "video_count": "1226",
        "subscriber_count": "675000",
        "clip_count": 79,
        "top_topics": [
            "minecraft",
            "apex",
            "pubg"
        ]
    },
    "alice": {
        "id": "UCt0clH12Xk1-Ej5PXKGfdPA",
        "name": "\u2665\ufe0f\u2660\ufe0f\u7269\u8ff0\u6709\u6816\u2666\ufe0f\u2663\ufe0f",
        "english_name": "Mononobe Alice",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRA6p4Y7X98R9eomkyXd0_ffp66auHBpV1Du5_Y=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "AliceMononobe",
        "video_count": "491",
        "subscriber_count": "245000",
        "clip_count": 26,
        "top_topics": [
            "asmr",
            "3D_Stream",
            "singing"
        ]
    },
    "akkun": {
        "id": "UCPH7d3YxfeH56FG-UyPAB1g",
        "name": "\u3042\u3063\u304f\u3093\u5927\u9b54\u738b",
        "english_name": "Akkun Daimaou",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS3nkPMX887Dq4j_jJHYlnfmA9GXj344CZFA-4iYg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "A_BOX_LAND",
        "video_count": "366",
        "subscriber_count": "44200",
        "clip_count": 2,
        "top_topics": [
            "pubg",
            "talk",
            "monhun"
        ]
    },
    "hakui": {
        "id": "UC6eWCld0KwmyHFbAqK3V-Rw",
        "name": "Koyori ch. \u535a\u8863\u3053\u3088\u308a - holoX -",
        "english_name": "Hakui Koyori",
        "type": "vtuber",
        "org": "Hololive",
        "group": "6th Generation (HoloX)",
        "photo": "https://yt3.ggpht.com/DNC5rGjlwA4Ae7rhpPmkv_QdjJPl8AfpQT9NcXTLvPbbriL-Y0Ni-gYfFH8uoiF6Ay1EP9VnZAk=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hakuikoyori",
        "video_count": "174",
        "subscriber_count": "543000",
        "clip_count": 1424,
        "top_topics": [
            "morning",
            "ark",
            "Pokemon"
        ]
    },
    "annin": {
        "id": "UCFahBR2wixu0xOex84bXFvg",
        "name": "Annin Miru Channel",
        "english_name": "Annin Miru",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/CRhdIqMTAjxrPJJcpKYEY_Yot20ByMcBid-nsSCcl8YFRCi2wzS_oQZ0bgx490bodZPZVqvDOQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "AnninMirudayo",
        "video_count": "448",
        "subscriber_count": "377000",
        "clip_count": 32,
        "top_topics": [
            "apex",
            "singing",
            "shorts"
        ]
    },
    "gwelu": {
        "id": "UC1QgXt46-GEvtNjEC1paHnw",
        "name": "\u30b0\u30a6\u30a7\u30eb\u30fb\u30aa\u30b9\u30fb\u30ac\u30fc\u30eb / Gwelu Os Gar \u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Gwelu Os Gar",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQzg8xJhnA1WC2kOqvtVKl8JWWr1vxk4vv6GkSX6w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Gwelu_os_gar",
        "video_count": "479",
        "subscriber_count": "362000",
        "clip_count": 290,
        "top_topics": [
            "shorts",
            "Mahjong",
            "apex"
        ]
    },
    "siska": {
        "id": "UC5qSx7KzdRwbsO1QmJc4d-w",
        "name": "Siska Leontyne \u3010NIJISANJI ID\u3011",
        "english_name": "Siska Leontyne",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji ID",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTZlSN-xKAvHVnVfQjn_y1q6XYJADmcERl9s4Qn=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "SiskaLeontyne",
        "video_count": "334",
        "subscriber_count": "108000",
        "clip_count": 291,
        "top_topics": [
            "talk",
            "minecraft",
            "apex"
        ]
    },
    "kyria": {
        "id": "UCLAl_kA_uPje7sH1TgJUnlw",
        "name": "Kyria Vtuber",
        "english_name": "Kyria",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/FJkeACVl9gJrZoN2YnNxb6arKiEBBz2VAQYMIaJvD9SkOLdVzX9djTfTTnT8_dYBN6u5LF1k3xI=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "KyriaVtuber",
        "video_count": "316",
        "subscriber_count": "51100",
        "clip_count": 2,
        "top_topics": [
            "residentevil",
            "undertale",
            "minecraft"
        ]
    },
    "fluor": {
        "id": "UC_T1YqknD5yrpVlupc-ZXzg",
        "name": "\u30a4\u30eb_\u30d5\u30ed\u30fc\u30e9",
        "english_name": "Ilu Fluor",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSZHTJD6dObsRLhGh9_WxKHBy3QYlS-lYLXT8cH=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Ilu_Fluor",
        "video_count": "219",
        "subscriber_count": "83100",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "zelda",
            "talk"
        ]
    },
    "youko": {
        "id": "UCBi8YaVyZpiKWN3_Z0dCTfQ",
        "name": "\u8d64\u7fbd\u8449\u5b50\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Akabane Youko",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Gamers",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT4-WKP_mKHW23LWQCteb55ETHV9nrCk0BvqReMsg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Youko_Akabane",
        "video_count": "211",
        "subscriber_count": "169000",
        "clip_count": 103,
        "top_topics": [
            "ark",
            "pubg",
            "Stardew_Valley"
        ]
    },
    "kyoko": {
        "id": "UCRV9d6YCYIMUszK-83TwxVA",
        "name": "\u8f5f\u4eac\u5b50/kyoko todoroki\u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Todoroki Kyoko",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "SEEDS",
        "photo": "https://yt3.ggpht.com/U-cGSPzDv4uks9oaX3DCE0oeRsI_QzwDtwYt-koDk_7deHb4Wzvl5KKzoh2D5xhpsDeE6b7W=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "KT_seeds",
        "video_count": "543",
        "subscriber_count": "117000",
        "clip_count": 45,
        "top_topics": [
            "talk",
            "drawing",
            "minecraft"
        ]
    },
    "touka": {
        "id": "UCHyYlV44ZYCXtbzs60Zce1g",
        "name": "Touka ch. / \u6075\u6bd4\u5bff\u3068\u3046\u304b",
        "english_name": "Touka Ebisu",
        "type": "vtuber",
        "org": "Masquerade",
        "group": null,
        "photo": "https://yt3.ggpht.com/vP8sLOh2OreCeAZ2gVu47jAE7zAWu3-Nk_UN2SsB95Bz16EcXb4ckpkNZhDyWEU_x2wVln4iY8o=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Ebisu_Touka",
        "video_count": "17",
        "subscriber_count": "6500",
        "clip_count": null,
        "top_topics": [
            "apex",
            "fallguys",
            "Super_Bunny_Man"
        ]
    },
    "miwao": {
        "id": "UCv6rts7X60zoL__5JPwdPGA",
        "name": "\u90a3\u52a0\u307f\u308f\u304a",
        "english_name": "Miwao",
        "type": "vtuber",
        "org": "Marbl_s",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT9slvFTG9wtvL8ULN6i9-qJnV5QpJqu7Qp5n6Q=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Miwao_chan",
        "video_count": "341",
        "subscriber_count": "1880",
        "clip_count": 27,
        "top_topics": [
            "talk",
            "singing",
            "Mahjong"
        ]
    },
    "oguri": {
        "id": "UC0RVF3KPjfS7WnH2xbpqghA",
        "name": "Mel Ch. \u30aa\u30b0\u30ea\u30e1\u30eb",
        "english_name": "Oguri Mel",
        "type": "vtuber",
        "org": "Hoshimeguri Gakuen",
        "group": null,
        "photo": "https://yt3.ggpht.com/r6KSowiQopuomnnCPwzL_cwIKBVXNgYh_ez-34dIXsWYXv6IrjsRkPhpQdr5Jh9jIdluyZQ6Rw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ogurimel",
        "video_count": "312",
        "subscriber_count": "61400",
        "clip_count": 15,
        "top_topics": [
            "Music_Cover",
            "umamusume",
            "celebration"
        ]
    },
    "kiara": {
        "id": "UCHsx4Hqa-1ORjQTh9TYDhww",
        "name": "Takanashi Kiara Ch. hololive-EN",
        "english_name": "Takanashi Kiara",
        "type": "vtuber",
        "org": "Hololive",
        "group": "English (Myth)",
        "photo": "https://yt3.ggpht.com/w7TKJYU7zmamFmf-WxfahCo_K7Bg2__Pk-CCBNnbewMG-77OZLqJO9MLvDAmH9nEkZH8OkWgSQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "takanashikiara",
        "video_count": "478",
        "subscriber_count": "1330000",
        "clip_count": 6722,
        "top_topics": [
            "minecraft",
            "Superchat_Reading",
            "talk"
        ]
    },
    "akuma": {
        "id": "UCckdfYDGrjojJM28n5SHYrA",
        "name": "Vox Akuma\u3010NIJISANJI EN\u3011",
        "english_name": "Vox Akuma",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/GdBB68_95kibrSmi2eCD6sNP3Ii3GTAthBneFalDlA8qRDrxqCPu4oCBSkrjuKcFwVpqI6UNiw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Vox_Akuma",
        "video_count": "98",
        "subscriber_count": "400000",
        "clip_count": 93,
        "top_topics": [
            "genshin",
            "talk",
            "fnaf"
        ]
    },
    "sonny": {
        "id": "UCuuAb_72QzK0M1USPMEl1yw",
        "name": "Sonny Brisko \u3010NIJISANJI EN\u3011",
        "english_name": "Sonny Brisko",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/Z6Pi1VkybXJu7D-yDxlSnL6hlavVbedb3qfkbCHc6zllK-Eo4lCqmNxpxc4XUM0IEFACqpdwsKo=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "sonny_brisko",
        "video_count": "11",
        "subscriber_count": "88800",
        "clip_count": 9,
        "top_topics": [
            "announce",
            "Metal_Gear",
            "shorts"
        ]
    },
    "asano": {
        "id": "UCODNLyn3L83wEmC0DLL0cxA",
        "name": "\u671d\u30ce\u59c9\u59b9\u3077\u308d\u3058\u3047\u304f\u3068",
        "english_name": "Asano Ninja Sisters",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/tUjgQhGzqsQHR0hn4I9fCh1mkYsTzhlsaXRcfAI4w7BQyFxlRDVZbvx0qviDCEytQ2et83sA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "1275",
        "subscriber_count": "176000",
        "clip_count": 55,
        "top_topics": [
            "singing",
            "Music_Cover",
            "talk"
        ]
    },
    "maemi": {
        "id": "UC3K7pmiHsNSx1y0tdx2bbCw",
        "name": "Tenma Ch. \u30de\u30a8\u30df \u5929\u6e80",
        "english_name": "Tenma Maemi",
        "type": "vtuber",
        "org": "Phase Connect",
        "group": null,
        "photo": "https://yt3.ggpht.com/ab1p41f1quWyteO6IHaYVEXpZHsoftG3TejXnFEffSGndsOI5vCYGIWPG6-Rj7dtxLLWOGrd9g=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "tenmamaemi",
        "video_count": "201",
        "subscriber_count": "25600",
        "clip_count": 118,
        "top_topics": [
            "undertale",
            "watchalong",
            "shorts"
        ]
    },
    "selen": {
        "id": "UCV1xUwfM2v2oBtT3JNvic3w",
        "name": "Selen Tatsuki \u3010NIJISANJI EN\u3011",
        "english_name": "Selen Tatsuki",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/OLpUvHlKRG9RrHxJo_QV0OYrh_bvUPpKI-MK80f8SBA2uTmre_Kx1Gq9lZhU6rmOEYlvo33Pp3M=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Selen_Tatsuki",
        "video_count": "214",
        "subscriber_count": "263000",
        "clip_count": 1037,
        "top_topics": [
            "apex",
            "membersonly",
            "Superchat_Reading"
        ]
    },
    "mumei": {
        "id": "UC3n5uGu18FoCy23ggWWp8tA",
        "name": "Nanashi Mumei Ch. hololive-EN",
        "english_name": "Nanashi Mumei",
        "type": "vtuber",
        "org": "Hololive",
        "group": "English (Council)",
        "photo": "https://yt3.ggpht.com/owZmjfnG_SGVmfkl3eS7Lv47pGvIr2SrS36imh6O8i0H3Wy41fYKD26U7wnyRB627fXoq0aQ0Q=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "nanashimumei_en",
        "video_count": "111",
        "subscriber_count": "581000",
        "clip_count": 2149,
        "top_topics": [
            "singing",
            "minecraft",
            "zelda"
        ]
    },
    "flare": {
        "id": "UCvInZx9h3jC2JzsIzoOebWg",
        "name": "Flare Ch. \u4e0d\u77e5\u706b\u30d5\u30ec\u30a2",
        "english_name": "Shiranui Flare",
        "type": "vtuber",
        "org": "Hololive",
        "group": "3rd Generation",
        "photo": "https://yt3.ggpht.com/d9aIrGtZR0eI4k1Wnev5f_R4llIBsWnQOjkdsqkMycoAxA3g_zz-jyeBl8tEHfbm1iTg0SJj=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "shiranuiflare",
        "video_count": "807",
        "subscriber_count": "865000",
        "clip_count": 5701,
        "top_topics": [
            "minecraft",
            "singing",
            "talk"
        ]
    },
    "hioki": {
        "id": "UCX3kDHyJjblueix0gAQ6aJA",
        "name": "Yachi CH.\u6c37\u7f6e\u516b\u8305",
        "english_name": "Hioki Yachi",
        "type": "vtuber",
        "org": "Hoshimeguri Gakuen",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQgSPFq9ZSvJtLJyl78ICZUnO6Z3MNziSFkg-7L=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hiokiyachi",
        "video_count": "11",
        "subscriber_count": "61300",
        "clip_count": null,
        "top_topics": [
            "apex",
            "Music_Cover",
            "talk"
        ]
    },
    "shark": {
        "id": "UCLMC54YctropOupFX8jyz9A",
        "name": "\u30a8\u30eb\u30bb\u3068\u3055\u3081\u306e\u307d\u304d",
        "english_name": "Else w/ Poki the Shark",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSacDuwte8EOYXgwo5W4lFWSrrysYwhz1UYM5RoQA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Else_PJblue",
        "video_count": "342",
        "subscriber_count": "46400",
        "clip_count": 3,
        "top_topics": [
            "Music_Cover",
            "minecraft",
            "talk"
        ]
    },
    "bando": {
        "id": "UCtjQoCilYbnxUXquXcVU3uA",
        "name": "\u3070\u3093\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Bando Riina",
        "type": "vtuber",
        "org": "Marbl_s",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR1X5BSntTo98y5jgi2SpX6OtypLd7P9OxOv5XePA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Bando004",
        "video_count": "767",
        "subscriber_count": "3430",
        "clip_count": 2,
        "top_topics": [
            "Assassins_Creed",
            "talk",
            "Pokemon"
        ]
    },
    "blanc": {
        "id": "UCoUFv7APM1XOo4TUaWbRekw",
        "name": "Reynard Blanc Ch.\u300e Re:Memories \u300f",
        "english_name": "Reynard Blanc",
        "type": "vtuber",
        "org": "ReMemories",
        "group": null,
        "photo": "https://yt3.ggpht.com/c28mMv2uV2ZMJHfW-w1SoZfNPu5PkKXeW4RLhRLMGOG7YxBB-6Gjs6rGqGqpwSggf2zdCcLd=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ReynardBlanc",
        "video_count": "230",
        "subscriber_count": "34200",
        "clip_count": 14,
        "top_topics": [
            "singing",
            "minecraft",
            "residentevil"
        ]
    },
    "shaun": {
        "id": "UC8vZcu6W-EJ6UYEy_C31c-A",
        "name": "\u52faShaun",
        "english_name": "Shaun",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "VirtuaReal (CN)",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTTfgbwgaQf0dgrLKoVN8ynF-8dGnQjpvqTJEZ1=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ShaunVirtuaReal",
        "video_count": "7",
        "subscriber_count": "1530",
        "clip_count": null,
        "top_topics": [
            "Music_Cover",
            "Cyberpunk_2077",
            "League_of_Legends"
        ]
    },
    "aruru": {
        "id": "UC3IEz9Vohkw1PLZR7fx8zqQ",
        "name": "Aruru Gray Ch. \u30a2\u30eb\u30eb\u30fb\u30b0\u30ec\u30a4\u3010kawaii\u3011",
        "english_name": "Aruru Gray",
        "type": "vtuber",
        "org": "Production Kawaii",
        "group": null,
        "photo": "https://yt3.ggpht.com/7J-8U-QAMKXai9HTv0gla8CvNjkDeJDJDYzwgE6v36Gsp1RdrJpFblGaG113H535xjjVB-aQ5A=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "gururu_kawaii",
        "video_count": "45",
        "subscriber_count": "7950",
        "clip_count": 12,
        "top_topics": [
            "Call_of_Duty",
            "Pokemon",
            "apex"
        ]
    },
    "amiya": {
        "id": "UCykMWf8B8I7c_jA8FTy2tGw",
        "name": "Amiya Ch. \u30a2\u30df\u30e4\u30fb\u30a2\u30e9\u30cb\u30e3\u3010Tsunderia\u3011",
        "english_name": "Amiya Aranha",
        "type": "vtuber",
        "org": "Tsunderia",
        "group": "Generation 3",
        "photo": "https://yt3.ggpht.com/91Fqx3Xut4jaSefCeiDLVGQDSdkWAvt5jA8ejzT18EztYTppy29JNzpa7G-KThC8TzuAmlKBQcs=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "amiyaaranha",
        "video_count": "109",
        "subscriber_count": "4510",
        "clip_count": null,
        "top_topics": [
            "residentevil",
            "singing",
            "Super Mario"
        ]
    },
    "sweet": {
        "id": "UCZsMm2mdQgg3AG8lP8s5KOA",
        "name": "Dali Channel\u3000\u30a6\u30a3\u30c3\u30c1\u30fb\u30c0\u30ea\u30fb\u30b9\u30a4\u30fc\u30c8",
        "english_name": "Dali Sweet",
        "type": "vtuber",
        "org": "KAWAII MUSIC",
        "group": null,
        "photo": "https://yt3.ggpht.com/7BcyPLKH333Rh_17cK7H4zh_YH8kLImo4TcfvyxzF6LiRwWsoFWKswE2dmQJHY3cCoaM7XDJ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Witchdalisweet",
        "video_count": "262",
        "subscriber_count": "5760",
        "clip_count": 1,
        "top_topics": [
            "apex",
            "asmr",
            "drawing"
        ]
    },
    "obear": {
        "id": "UCW5O-tjdwofBwfispeMSPfw",
        "name": "Obear Ch. \u6b50\u8c9d\u723e",
        "english_name": "Obear",
        "type": "vtuber",
        "org": "SquareLive",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQqOHV49bP2LLZUeJigUsM88W-dMOahKA95PXLi=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Obear_rescute",
        "video_count": "147",
        "subscriber_count": "31300",
        "clip_count": 42,
        "top_topics": [
            "minecraft",
            "singing",
            "It_takes_two"
        ]
    },
    "alban": {
        "id": "UCQ1zGxHrfEmmW4CPpBx9-qw",
        "name": "Alban Knox \u3010NIJISANJI EN\u3011",
        "english_name": "Alban Knox",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/rbTGK5bXuub0l3feyx8c780FtoRAtT1KDgqOGQq2wSisQJo17wk9PusEc_ZjUMK_uwZ47LIZiSI=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "alban_knox",
        "video_count": "14",
        "subscriber_count": "108000",
        "clip_count": 11,
        "top_topics": [
            "shorts",
            "Convenience_Store",
            "getting_over_it"
        ]
    },
    "reimu": {
        "id": "UCBURM8S4LH7cRZ0Clea9RDA",
        "name": "Reimu Endou\u3010NIJISANJI EN\u3011",
        "english_name": "Reimu Endou",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/VxzFz_Dvepexh6qF_j3-Sp1HsKz9u_t0YegWeQ4L_Vegz-BHMHSrpIo8BAE46Rv4AwsYDK_-Hw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ReimuEndou",
        "video_count": "140",
        "subscriber_count": "153000",
        "clip_count": 298,
        "top_topics": [
            "apex",
            "membersonly",
            "talk"
        ]
    },
    "nagao": {
        "id": "UCXW4MqCQn-jCaxlX-nn-BYg",
        "name": "\u9577\u5c3e \u666f / Nagao Kei\u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Nagao Kei",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2020",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLT2z2m_TW6ClXaMu24zuw3_IP5hksYIwDhUibCd=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kei_nagao2434",
        "video_count": "740",
        "subscriber_count": "244000",
        "clip_count": 121,
        "top_topics": [
            "minecraft",
            "apex",
            "Splatoon"
        ]
    },
    "issei": {
        "id": "UCR4HhH-VSMQjySd0qoVh91g",
        "name": "Issei Kai Ch. \u4e00\u751f\u30ab\u30a4",
        "english_name": "Issei Kai",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/klWgkR79Wh1PklCJ4c-MlnQq5cLXYItgkIXU2Dv6ZSC3NSi924PN-egSKJbXpIN6MI9hqvBE=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "isseikaich",
        "video_count": "344",
        "subscriber_count": "56600",
        "clip_count": 4,
        "top_topics": [
            "singing",
            "apex",
            "talk"
        ]
    },
    "touri": {
        "id": "UCBxw5bdrbKO7E60E4s3KgQg",
        "name": "Sei Channel / \u6a59\u91cc\u30bb\u30a4\u3010\u3072\u3088\u30af\u30ed\u3011",
        "english_name": "Sei Touri",
        "type": "vtuber",
        "org": "774inc",
        "group": "HIYOCRO",
        "photo": "https://yt3.ggpht.com/V4kuuDQ2XtOT0er7XC3J2RfqOkrFCl7eVVrlEEU-WvCwz6112gzzfhpT3V3OZ2Q5jUQJ69D2xMM=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Sei_Touri",
        "video_count": "79",
        "subscriber_count": "5830",
        "clip_count": 1,
        "top_topics": [
            "singing",
            "minecraft",
            "talk"
        ]
    },
    "shito": {
        "id": "UCUKngXhjnKJ6KCyuC7ejI_w",
        "name": "\u7d2b\u6843\u3042\u306e\u3093\u3010Crazy Cooking Devil Anon Shito\u3011",
        "english_name": "Shito Anon",
        "type": "vtuber",
        "org": "Chukorara",
        "group": null,
        "photo": "https://yt3.ggpht.com/f5-Z2S44vBJTJ4so359kS1I1n0XPl46Xq7knHoDx_G6XDZdVd7qVHnPwKturv1fHSR9vEWBK=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Shito_ANON",
        "video_count": "349",
        "subscriber_count": "13100",
        "clip_count": 2,
        "top_topics": [
            "Cooking Stream",
            "talk",
            "singing"
        ]
    },
    "petra": {
        "id": "UCgA2jKRkqpY_8eysPUs8sjw",
        "name": "Petra Gurin\u3010NIJISANJI EN\u3011",
        "english_name": "Petra Gurin",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/kBgInADvG3nt08S2796EVhXhN9SqUPdNir2jCSfioymem3qdnQqw1YSqOwgWa5UPFOoCY44n=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Petra_Gurin",
        "video_count": "170",
        "subscriber_count": "245000",
        "clip_count": 769,
        "top_topics": [
            "membersonly",
            "talk",
            "Phasmophobia"
        ]
    },
    "music": {
        "id": "UCGH_ty6YBy2Q9KWtiRYm2IQ",
        "name": "RIOT MUSIC",
        "english_name": "Riot Music Official",
        "type": "vtuber",
        "org": "Riot Music",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLShN5iqpG5nHTSeexqEK0sn04deTjr3AK_WCvc=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "RIOTMUSIC_info",
        "video_count": "7",
        "subscriber_count": "8120",
        "clip_count": null,
        "top_topics": [
            "watchalong"
        ]
    },
    "suika": {
        "id": "UChbnRHntm3eSvUazYBjy6_Q",
        "name": "Keroro Suika \u3018 Yume Reality \u3019",
        "english_name": "Keroro Suika",
        "type": "vtuber",
        "org": "Yume Reality",
        "group": null,
        "photo": "https://yt3.ggpht.com/mpFzaK71_-xRWDkxp-UurZhxwMpb0R5aukuPp4l5qI0IwdKo1FhX3-ORA-gHXgcsdvQr_QQjcg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "KeroroSuika",
        "video_count": "35",
        "subscriber_count": "26200",
        "clip_count": 15,
        "top_topics": [
            "shorts",
            "singing",
            "talk"
        ]
    },
    "choko": {
        "id": "UCoupL81t75_Z4XRKzOSHLbg",
        "name": "\u3061\u3087\u3053",
        "english_name": "Choko",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTCwPYAcMn0Wa21cEmXQ-gZ6Inpu7_z5iIkneFNww=s800-c-k-c0x00ffffff-no-rj-mo",
        "twitter": "cho_v_ko",
        "video_count": "50",
        "subscriber_count": "6810",
        "clip_count": 4,
        "top_topics": [
            "Music_Cover",
            "talk",
            "singing"
        ]
    },
    "mocha": {
        "id": "UCOobfYvyNQqWHBpEaMnXr7w",
        "name": "Mochatan ch. / \u840c\u3005\u5ac1\u3082\u304b",
        "english_name": "Mocha Momoka",
        "type": "vtuber",
        "org": "Masquerade",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQ31w3AqVEOqnJ-zqZJtT50vNRrFa-jfSD4FB1k=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "mochatan0214",
        "video_count": "139",
        "subscriber_count": "137000",
        "clip_count": null,
        "top_topics": [
            "fallguys",
            "asmr",
            "talk"
        ]
    },
    "erina": {
        "id": "UCTew_otbB25Ui2TQp2svHbg",
        "name": "\u305f\u307e\u3048\u308a\u30e9\u30a4\u30d6\u30c1\u30e3\u30f3\u30cd\u30eb\u3010PinkPunkPro\u6240\u5c5e\u3011",
        "english_name": "Tamaki Erina",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS_0dNWV05uIMeq0JrY05lBkFFnzC8Hut8Qipde=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "TamakiErina",
        "video_count": "251",
        "subscriber_count": "68400",
        "clip_count": null,
        "top_topics": [
            "shorts",
            "asmr",
            "genshin"
        ]
    },
    "moona": {
        "id": "UCP0BspO_AMEe3aQqqpo89Dg",
        "name": "Moona Hoshinova hololive-ID",
        "english_name": "Moona Hoshinova",
        "type": "vtuber",
        "org": "Hololive",
        "group": "Indonesia 1st Gen",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRaHP1Qoi3zFxbQYdbX4MNnV18TrqjFBwDxgTlNqg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "moonahoshinova",
        "video_count": "377",
        "subscriber_count": "1000000",
        "clip_count": 3407,
        "top_topics": [
            "minecraft",
            "talk",
            "singing"
        ]
    },
    "gyari": {
        "id": "UCOl0Rfe3Erum2cP74y7Eayw",
        "name": "GYARI",
        "english_name": "GYARI",
        "type": "vtuber",
        "org": "VOMS",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRWScWXFXKL63OVMUI_kUn4dUH1qfMYEq10Y5JvgQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "GYARI_",
        "video_count": "25",
        "subscriber_count": "448000",
        "clip_count": 4,
        "top_topics": [
            "announce",
            "Splatoon",
            "Original_Song"
        ]
    },
    "lenri": {
        "id": "UCOrjRyXJpMzfQFLWuWzGwlg",
        "name": "\u82a6\u679d\u30ec\u30f3\u30ea:LENRI channel",
        "english_name": "Ashieda Lenri",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/81aqJfuWAYyQnuYI3Fw6fdAEqM8M6cnTQizcEt5X60oYv7EUQsNtmqHClo3VQVq2aX_uegfCbg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "",
        "video_count": "334",
        "subscriber_count": "20800",
        "clip_count": 2,
        "top_topics": [
            "Gundam_Battle_Operation",
            "Mahjong",
            "apex"
        ]
    },
    "ritsu": {
        "id": "UCL9dLCVvHyMiqjp2RDgowqQ",
        "name": "\u9ad8\u69fb\u308a\u3064 / Takatsuki ritsu",
        "english_name": "Takatsuki Ritsu",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSLm6SKbyFaQ4SBryKAbOT6PGZHlVsWOGO_PdObng=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "re2_takatsuki",
        "video_count": "87",
        "subscriber_count": "43400",
        "clip_count": null,
        "top_topics": [
            "singing",
            "apex",
            "talk"
        ]
    },
    "inuki": {
        "id": "UCFrH3bfn7gDeV29wrHHM80g",
        "name": "\u5c04\u8cab\u307e\u3068\u3044\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Inuki Matoi",
        "type": "vtuber",
        "org": "ReAcT",
        "group": null,
        "photo": "https://yt3.ggpht.com/4wqgVYh6yG7LfUO4j9Xst_rmqFwiqe2xgfVgM9s5LOUqaVc7hGaAXFgct_k41VljrCE78aAVB1Y=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "matoi_inuki",
        "video_count": "552",
        "subscriber_count": "48300",
        "clip_count": 154,
        "top_topics": [
            "asmr",
            "singing",
            "Music_Cover"
        ]
    },
    "ifrit": {
        "id": "UCxm2qC7Z7cjDAd6yPyl-sKQ",
        "name": "Asa Ifrit Ch.",
        "english_name": "Asa Ifrit",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQWQ8kRdOHFBLKIgC119Y5_O3KmUAwIJ8k8h-Si=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "AsaIfrit",
        "video_count": "275",
        "subscriber_count": "41500",
        "clip_count": 1,
        "top_topics": [
            "Pokemon",
            "Marshmallow",
            "Miitopia"
        ]
    },
    "yachi": {
        "id": "UCX3kDHyJjblueix0gAQ6aJA",
        "name": "Yachi CH.\u6c37\u7f6e\u516b\u8305",
        "english_name": "Hioki Yachi",
        "type": "vtuber",
        "org": "Hoshimeguri Gakuen",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQgSPFq9ZSvJtLJyl78ICZUnO6Z3MNziSFkg-7L=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hiokiyachi",
        "video_count": "11",
        "subscriber_count": "61300",
        "clip_count": null,
        "top_topics": [
            "apex",
            "Music_Cover",
            "talk"
        ]
    },
    "temma": {
        "id": "UCGNI4MENvnsymYjKiZwv9eg",
        "name": "Temma Ch. \u5cb8\u5802\u5929\u771f",
        "english_name": "Kishido Temma",
        "type": "vtuber",
        "org": "Hololive",
        "group": "Holostars 2nd Gen",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRUGIQekUO7Yyzzx49nHpRozhPKLUmNDnEAL4Go=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kishidotemma",
        "video_count": "685",
        "subscriber_count": "114000",
        "clip_count": 287,
        "top_topics": [
            "apex",
            "dbd",
            "minecraft"
        ]
    },
    "runis": {
        "id": "UCupCAZz1l52vV8m-dvaoBVQ",
        "name": "Overidea\u516c\u5f0f/\u30aa\u30fc\u30d0\u30fc\u30e9\u30a4\u30c7\u30a3\u30a2",
        "english_name": "Mia Runis",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ZBdFzZ4DFNA2pwsMgpClYiDoHADjF17W-FbinXbw_zkWbI0Lbez_VqTy4n8VKj8OO3D8bt4GWg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "MiaRunis",
        "video_count": "82",
        "subscriber_count": "33500",
        "clip_count": 1,
        "top_topics": [
            "Music_Cover",
            "outlast",
            "pubg"
        ]
    },
    "moemi": {
        "id": "UCIwHOJn_3QjBTwQ_gNj7WRA",
        "name": "\u840c\u5b9f\u3061\u3083\u3093\u306d\u308b\u3002",
        "english_name": "Moemi",
        "type": "vtuber",
        "org": "Eilene Family",
        "group": null,
        "photo": "https://yt3.ggpht.com/fu4QDFJSmDvXbmYtiXlyO09hTNCidl7pVwptGhsYNcV1fNkD6WeJWdjEhx6KgTkG3Jl8e2Qp5ms=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Moemi_Yomeno",
        "video_count": "89",
        "subscriber_count": "16800",
        "clip_count": null,
        "top_topics": [
            "talk",
            "drawing",
            "Anniversary"
        ]
    },
    "games": {
        "id": "UCjtEttSHpJacfd7a6fc4VXQ",
        "name": "\u30d0\u30fc\u30c1\u30e3\u30eb\u30b4\u30ea\u30e9GAMES",
        "english_name": "Virtual Gorilla Games",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLST1GVIjFmxECKEGh6Qde3YQ7hF_zk0R-EA9E20=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "782",
        "subscriber_count": "145000",
        "clip_count": 32,
        "top_topics": [
            "apex",
            "Valheim",
            "among_us"
        ]
    },
    "kuroi": {
        "id": "UCmeyo5pRj_6PXG-CsGUuWWg",
        "name": "\u9ed2\u4e95\u3057\u3070\u3010\u306b\u3058\u3055\u3093\u3058\u306e\u72ac\u3011",
        "english_name": "Kuroi Shiba",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "SEEDS",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRKf9p1esL7DeVqMWITAVJXQknx0jrC9m91Sljl=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "BlackShiba_chan",
        "video_count": "389",
        "subscriber_count": "184000",
        "clip_count": 49,
        "top_topics": [
            "Final_fantasy",
            "minecraft",
            "talk"
        ]
    },
    "azumi": {
        "id": "UC5YTIcyZ_lstc3FstTX4acA",
        "name": "\u30a2\u30ba\u30df\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Utai Azumi",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSNk-6kAQybYTzVLmFVF6cuHq2w50o2Vwku0U_iZA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "As_me_V",
        "video_count": "163",
        "subscriber_count": "23400",
        "clip_count": 4,
        "top_topics": [
            "singing",
            "talk",
            "among_us"
        ]
    },
    "shell": {
        "id": "UCz1RAeb7dVaUcd-4rEgyxPw",
        "name": "\u6d77\u6708\u30b7\u30a7\u30eb Shell Ch.",
        "english_name": "Umitsuki Shell Ch.",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQRPy87Xxg71m1ZSumOhKtELFIFCO6Swz1DuEdk=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "umitsukishell",
        "video_count": "244",
        "subscriber_count": "27600",
        "clip_count": 5,
        "top_topics": [
            "singing",
            "talk",
            "Pokemon"
        ]
    },
    "uruca": {
        "id": "UChrTMfNcD1eN2ENGWBD7lUQ",
        "name": "Uruca ch /\u3046\u308b\u304b",
        "english_name": "Uruca",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/03hUmg1J16YxxQhMisCH8WHiaaH0EFEuUrsQNdtxJhIg3WlEXJ6RIzBXwGTqAKLI2VPyeWoLyEg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "285",
        "subscriber_count": "206000",
        "clip_count": 18,
        "top_topics": [
            "apex",
            "valorant",
            "Overwatch"
        ]
    },
    "ranka": {
        "id": "UCxmbiuI6Gyyln9Ec7uZ-8wQ",
        "name": "RANKA GAMES",
        "english_name": "Kosumi Ranka",
        "type": "vtuber",
        "org": "V Dimension.Creators",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRCjo6-bJtW-OYFBTaQIE1lXrD9v15DIjDdkB0rAw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kosumi_ranka",
        "video_count": "44",
        "subscriber_count": "4000",
        "clip_count": 2,
        "top_topics": [
            "asmr",
            "Music_Cover",
            "talk"
        ]
    },
    "yuuri": {
        "id": "UCh03lxwbX8zXkeGyOMZJ9Ow",
        "name": "YuuRi Channel",
        "english_name": "YuuRi",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/vEskyeepsWM9zz1bmFVCpo-bC8YJ5cqr81RQha04oSD2hs9HsG6nEph3TB9hxeKs-tB-eDulNZE=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "yuuriu95",
        "video_count": "227",
        "subscriber_count": "97500",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "singing",
            "Music_Cover"
        ]
    },
    "yuiga": {
        "id": "UCf6J4I7NwMXtuIU8YZKAjCA",
        "name": "Kohaku Ch / \u552f\u7259\u30b3\u30cf\u30af",
        "english_name": "Yuiga Kohaku",
        "type": "vtuber",
        "org": "ReAcT",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRhZ49b0vo3tex89Zv3VSh7BT7x12H3nhpMIgA4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kohaku_yuiga",
        "video_count": "21",
        "subscriber_count": "11500",
        "clip_count": 13,
        "top_topics": [
            "singing",
            "Music_Cover",
            "Power_Pros"
        ]
    },
    "rurun": {
        "id": "UCcd4MSYH7bPIBEUqmBgSZQw",
        "name": "\u30eb\u30eb\u30f3\u30fb\u30eb\u30eb\u30ea\u30ab",
        "english_name": "Rurun Rururica",
        "type": "vtuber",
        "org": ".LIVE",
        "group": null,
        "photo": "https://yt3.ggpht.com/rEAdomUjoJZLx6q2RdDyfHcD3wF0_3OMuWrnMyrpuWy_J3UIXpKJJp2iX9oertCKD_avGlBPhTc=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Rururica_VTuber",
        "video_count": "245",
        "subscriber_count": "23500",
        "clip_count": 68,
        "top_topics": [
            "Miitopia",
            "Mahjong",
            "watchalong"
        ]
    },
    "hakos": {
        "id": "UCgmPnx-EEeOrZSg5Tiw7ZRQ",
        "name": "Hakos Baelz Ch. hololive-EN",
        "english_name": "Hakos Baelz",
        "type": "vtuber",
        "org": "Hololive",
        "group": "English (Council)",
        "photo": "https://yt3.ggpht.com/7gFSRIM3_DhczV8AYjeP4EaS0OL-u_xLvIh9JhG9zJhPYEfVwsoUOK61L6eBlLjnPHN-EDvytQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "hakosbaelz",
        "video_count": "159",
        "subscriber_count": "494000",
        "clip_count": 2803,
        "top_topics": [
            "minecraft",
            "talk",
            "membersonly"
        ]
    },
    "omaru": {
        "id": "UCK9V2B22uJYu3N7eR_BT9QA",
        "name": "Polka Ch. \u5c3e\u4e38\u30dd\u30eb\u30ab",
        "english_name": "Omaru Polka",
        "type": "vtuber",
        "org": "Hololive",
        "group": "5th Generation",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQI_iYxOpfP8bJklQ_VnS4a9jdrwRRlre_JP1Yp=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "omarupolka",
        "video_count": "598",
        "subscriber_count": "938000",
        "clip_count": 3832,
        "top_topics": [
            "minecraft",
            "singing",
            "ark"
        ]
    },
    "kaede": {
        "id": "UCsg-YqdqQ-KFF0LNk23BY4A",
        "name": "\u6a0b\u53e3\u6953\u3010\u306b\u3058\u3055\u3093\u3058\u6240\u5c5e\u3011",
        "english_name": "Higuchi Kaede",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 1",
        "photo": "https://yt3.ggpht.com/xGQ_-9VQMpoWBMSPlhx6zdQnOTH6ThIVDD5astbuhC49itMZHVqS6fBh3p4a87TioLBsNeftbA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "HiguchiKaede",
        "video_count": "756",
        "subscriber_count": "491000",
        "clip_count": 527,
        "top_topics": [
            "apex",
            "minecraft",
            "Pokemon"
        ]
    },
    "mysta": {
        "id": "UCIM92Ok_spNKLVB5TsgwseQ",
        "name": "Mysta Rias \u3010NIJISANJI EN\u3011",
        "english_name": "Mysta Rias",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/38atDMxhFxOVYLKvpttf6DpVzF_kMshpPvyH36p1a63l-GSGTKT6fre5T2KQfBV6abWZGa0i=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Mysta_Rias",
        "video_count": "74",
        "subscriber_count": "345000",
        "clip_count": 185,
        "top_topics": [
            "shorts",
            "talk",
            "minecraft"
        ]
    },
    "yagoo": {
        "id": "UCu2DMOGLeR_DSStCyeQpi5Q",
        "name": "tanigox",
        "english_name": "YAGOO",
        "type": "vtuber",
        "org": "Hololive",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLREaobaGG3WszgfO8im9Kw-hxDWDEs94MSrBIicXh0=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "tanigox",
        "video_count": "7",
        "subscriber_count": "203000",
        "clip_count": 42,
        "top_topics": [
            "It_takes_two"
        ]
    },
    "uzuki": {
        "id": "UC3lNFeJiTq6L3UWoz4g1e-A",
        "name": "\u536f\u6708\u30b3\u30a6",
        "english_name": "Uzuki Kou",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "SEEDS",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRUZ3Ao9h5tNpWuS6mlttaD4R52HL2VIfv0-_R1=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "udukikohh",
        "video_count": "519",
        "subscriber_count": "195000",
        "clip_count": 121,
        "top_topics": [
            "Pokemon",
            "talk",
            "apex"
        ]
    },
    "canna": {
        "id": "UCkpqb53xiOvOgNYEbNpFSyw",
        "name": "Canna Channel / \u831c\u97f3\u30ab\u30f3\u30ca\u3010\u3072\u3088\u30af\u30ed\u3011",
        "english_name": "Canna Akane",
        "type": "vtuber",
        "org": "774inc",
        "group": "HIYOCRO",
        "photo": "https://yt3.ggpht.com/hmCWg4U-BSCQ2__ZI1f5zEhw8o4Aj8ppobywwi_ZTyT72ndVMGj-FZuQ_7j-XV124j8XuJZY8vg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Canna_Akane",
        "video_count": "67",
        "subscriber_count": "5820",
        "clip_count": 3,
        "top_topics": [
            "singing",
            "talk",
            "Mahjong"
        ]
    },
    "yuuka": {
        "id": "UCiqtXLBjDT6TRLhetkqO4nA",
        "name": "Yuuka Bear Ch.",
        "english_name": "Yuuka Bear",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/OBrzJM-_sz168Jm9Gb2tfthSbD55DTjkvG8Qeya4L52XFyxTqCFUwSw4d0_BYQyIaVIQQ5kKZ7Y=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "YuukaBear1",
        "video_count": "532",
        "subscriber_count": "118000",
        "clip_count": 2,
        "top_topics": [
            "Pokemon",
            "minecraft",
            "shorts"
        ]
    },
    "azuki": {
        "id": "UCmM5LprTu6-mSlIiRNkiXYg",
        "name": "\u6728\u66fd\u3042\u305a\u304d",
        "english_name": "Kiso Azuki",
        "type": "vtuber",
        "org": ".LIVE",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSpLIPS6kX6RLeuz431p20w3GHnbFtZzLICZvpY=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "KisoAzuki",
        "video_count": "38",
        "subscriber_count": "61200",
        "clip_count": 2,
        "top_topics": [
            "minecraft",
            "talk",
            "drawing"
        ]
    },
    "morph": {
        "id": "UCE0yFdA-ZiCKVCqhHdVCV0Q",
        "name": "MBD\u6848\u5185\u6240 /\u30df\u30df\u30ab\u30e2\u30fc\u30d5 -mimika morph -",
        "english_name": "Mimika Morph",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRxe0yP2yNRButR_RsV9GiDySd8ti5rQh6axdqWkA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "mimika_morph",
        "video_count": "256",
        "subscriber_count": "23400",
        "clip_count": null,
        "top_topics": [
            "watchalong",
            "residentevil",
            "shadow_corridor"
        ]
    },
    "amaki": {
        "id": "UCIqfGSGcz_q7zkZYlmU04GQ",
        "name": "AMAKI OKOME CHANNEL ",
        "english_name": "Amaki Okome",
        "type": "vtuber",
        "org": "KAWAII MUSIC",
        "group": null,
        "photo": "https://yt3.ggpht.com/zE7Au3xUdRWemDR196KfYwfd1rbmqfEvKOf4uDbcataPBjMgwJS7ZaZuAYnlYsmoVxG2ChDDwA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "a_okome_channel",
        "video_count": "130",
        "subscriber_count": "40400",
        "clip_count": null,
        "top_topics": [
            "Pokemon",
            "Original_Song",
            "Music_Cover"
        ]
    },
    "akane": {
        "id": "UCe_p3YEuYJb8Np0Ip9dk-FQ",
        "name": "\u671d\u65e5\u5357\u30a2\u30ab\u30cd / Asahina Akane \u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Asahina Akane",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2020",
        "photo": "https://yt3.ggpht.com/O8kXqY0UQgIGm3xyVYl3Az5xkDyyfyU54mvzf8MUIyYtDy9fbEX_7ujQEO30MvnCkptDb8p-=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "akane_asahina__",
        "video_count": "261",
        "subscriber_count": "171000",
        "clip_count": 49,
        "top_topics": [
            "shorts",
            "singing",
            "Music_Cover"
        ]
    },
    "padko": {
        "id": "UC2yG-9ekUwTs8Q0yMSycMxA",
        "name": "Padko Ch. \u5e73\u5e73\u5b50",
        "english_name": "Padko",
        "type": "vtuber",
        "org": "SquareLive",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSxytgNmjGDhkleKO2Lh4_PC0o0mSZijTwsGEfz=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Padko_tablet",
        "video_count": "236",
        "subscriber_count": "49800",
        "clip_count": 5,
        "top_topics": [
            "minecraft",
            "Superchat_Reading",
            "singing"
        ]
    },
    "cerys": {
        "id": "UCbD_f85AMHomsGVFAgtpE5A",
        "name": "Cerys Lobelia \u3018 Yume Reality \u3019",
        "english_name": "Cerys Lebelia",
        "type": "vtuber",
        "org": "Yume Reality",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQj5-BfmQpSGzpQRbqCMnUm8zWkxqc2A4F8Y9cCEQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "CerysLobelia",
        "video_count": "235",
        "subscriber_count": "46900",
        "clip_count": 24,
        "top_topics": [
            "apex",
            "shorts",
            "valorant"
        ]
    },
    "shion": {
        "id": "UCXTpFs_3PqI41qX2d9tL2Rw",
        "name": "Shion Ch. \u7d2b\u54b2\u30b7\u30aa\u30f3",
        "english_name": "Murasaki Shion",
        "type": "vtuber",
        "org": "Hololive",
        "group": "2nd Generation",
        "photo": "https://yt3.ggpht.com/AyUL9W0ltc_aJr_MysuZBx8hRfb1SIVNREgU9kiOO-lqmdhYkEsllmhagertVIwHwa3UAAKy=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "murasakishionch",
        "video_count": "419",
        "subscriber_count": "980000",
        "clip_count": 4034,
        "top_topics": [
            "minecraft",
            "talk",
            "Pokemon"
        ]
    },
    "pippa": {
        "id": "UCJ46YTYBQVXsfsp8-HryoUA",
        "name": "Pipkin Pippa Ch. \u30d4\u30c3\u30d7\u30ad\u30f3 \u30d4\u30c3\u30d1",
        "english_name": "Pipkin Pippa",
        "type": "vtuber",
        "org": "Phase Connect",
        "group": null,
        "photo": "https://yt3.ggpht.com/Uv6EuTjobBEKwGigQTJnfr-5DkrC1fL3EshHqaSTPfBrYdiOfG6bGWJd8jCTpSizv0Ci-ZrvboI=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "pipkinpippa",
        "video_count": "220",
        "subscriber_count": "27900",
        "clip_count": 18,
        "top_topics": [
            "shorts",
            "minecraft",
            "talk"
        ]
    },
    "konbu": {
        "id": "UCAIFyekjtlga7IfOTb-25Eg",
        "name": "\u6606\u5e03\u308f\u304b\u3081",
        "english_name": "Konbu Wakame",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTIkshqVemT_Jc4FcRs7X-v9we-3YX1qrYg4To4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "aconbwakame",
        "video_count": "31",
        "subscriber_count": "49800",
        "clip_count": null,
        "top_topics": [
            "drawing",
            "fallguys",
            "Animal_Crossing"
        ]
    },
    "nemea": {
        "id": "UCYOjhswZ0y5KYoLdMNR5QvQ",
        "name": "Nemea CH.\u4f0a\u7e54\u306d\u3081\u3042",
        "english_name": "Iori Nemea",
        "type": "vtuber",
        "org": "Hoshimeguri Gakuen",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLScCbGWdkoORPsqe42lnmBEUqLqV7Uv_KqRJxRV=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "iorinemea",
        "video_count": "224",
        "subscriber_count": "6450",
        "clip_count": null,
        "top_topics": [
            "apex",
            "singing",
            "talk"
        ]
    },
    "makea": {
        "id": "UC7-N7MvN5muVIHqyQx9LFbA",
        "name": "\u30e1\u30a4\u30ab\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Utai Makea",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQFLplXhASdKb27IgkXJ-jvvcEXIJ0EJbfmam-T7Q=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "meikahaotoko",
        "video_count": "1816",
        "subscriber_count": "328000",
        "clip_count": 47,
        "top_topics": [
            "Mahjong",
            "apex",
            "Duel_Masters"
        ]
    },
    "cammy": {
        "id": "UCb6ObE-XGCctO3WrjRZC-cw",
        "name": "\u30eb\u30a4\u30b9\u30fb\u30ad\u30e3\u30df\u30fc",
        "english_name": "Luis Cammy",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTTbL4Zvhb82JcPEF09ZcGlbljg27NsYdSP1R_N=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Luis_Cammy",
        "video_count": "529",
        "subscriber_count": "189000",
        "clip_count": 133,
        "top_topics": [
            "Mahjong",
            "minecraft",
            "talk"
        ]
    },
    "albio": {
        "id": "UCIytNcoz4pWzXfLda0DoULQ",
        "name": "\u30a8\u30af\u30b9\u30fb\u30a2\u30eb\u30d3\u30aa -Ex Albio-",
        "english_name": "Ex Albio",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTgcFAWo8ogKuv9d5aBN5E8sW2IIAbGXqZZH6m7=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ex_albio",
        "video_count": "762",
        "subscriber_count": "304000",
        "clip_count": 402,
        "top_topics": [
            "apex",
            "Splatoon",
            "ark"
        ]
    },
    "chibi": {
        "id": "UCoqNvjhUxJRYW4JsT5fBK6w",
        "twitter": "chibidokii",
        "name": "Chibi",
        "photo": "https://pbs.twimg.com/profile_images/1497238922157596684/TahAy_ma_400x400.jpg"
    },
    "nyoro": {
        "id": "UC4J0GZLM55qrFh2L-ZAb2LA",
        "name": "0Vanilla Nyoro \u9999\u8349\u5948\u82e5",
        "english_name": "Vanilla Nyoro",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/P2jw5Pw7Gy-oOsZ1ej3DrLMHy_ncCA4EYI1UTy8wWZzV7sp_xVUtuP9ZGgm4TjXfvlDeY6tj=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "NyoroVanilla",
        "video_count": "550",
        "subscriber_count": "50600",
        "clip_count": 7,
        "top_topics": [
            "League_of_Legends",
            "shorts",
            "singing"
        ]
    },
    "tarou": {
        "id": "UC0e6zo8oX6ISSBjPaSZsjeA",
        "name": "\u61f2\u5f79\u592a\u90ce\u30c1\u30e3\u30f3\u30cd\u30eb",
        "english_name": "Choueki Tarou",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/fOOjnAcpiXk9CXdgflZqzVh49LzKNYnDqbCUjJSCd9cYvi8rbjTCIsGppIsbzfbXsVWh9w3Mkg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "choueki_tarou",
        "video_count": "829",
        "subscriber_count": "411000",
        "clip_count": 9,
        "top_topics": [
            "Yakuza",
            "singing",
            "celebration"
        ]
    },
    "asagi": {
        "id": "UCZ2rFJTs3IPJzYfxtvbKp8Q",
        "name": "Saki Ch. \u6d45\u8471\u30b5\u30ad : VOICE-ORE / \u307c\u3044\u305d\u30fc\u308c",
        "english_name": "Asagi Saki",
        "type": "vtuber",
        "org": "VOICE-ORE",
        "group": null,
        "photo": "https://yt3.ggpht.com/Cp0beyno_MaYVhsAfYZToiCVsYWx9xAEm1baj9PesOC2sNlcwwDfg4XPpEw_eTAhyvgoXGkb3w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "asagi_saki_",
        "video_count": "129",
        "subscriber_count": "2970",
        "clip_count": null,
        "top_topics": [
            "Pokemon",
            "minecraft",
            "Superchat_Reading"
        ]
    },
    "aruka": {
        "id": "UCTUYzaLYcTnKCsw1lHX2YzQ",
        "name": "Aruka Ch. \u3042\u308b\u304b",
        "english_name": "Aruka",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQPisgYAF5-zbl0j3i-RUxK_mKZN2iwlr0_TIc1Ww=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ark03_",
        "video_count": "58",
        "subscriber_count": "135000",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "Music_Cover",
            "Stardew_Valley"
        ]
    },
    "akari": {
        "id": "UCMYtONm441rBogWK_xPH9HA",
        "name": "Mirai Akari Project",
        "english_name": "Mirai Akari",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS09Ph0eazxNGOTp7NE2VVbeF_0vHYcjW5UOCRraA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "MiraiAkari_prj",
        "video_count": "475",
        "subscriber_count": "711000",
        "clip_count": 12,
        "top_topics": [
            "Original_Song",
            "Music_Cover",
            "asmr"
        ]
    },
    "omega": {
        "id": "UCNjTjd2-PMC8Oo_-dCEss7A",
        "name": "\u304a\u3081\u304c\u30b7\u30b9\u30bf\u30fc\u30ba [\u03a9 Sisters]",
        "english_name": "Omega Sisters",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRQbg2x4WfDti1ONZC0zIYVoscuk6OyZB_M66UNNw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "omesis_rio",
        "video_count": "786",
        "subscriber_count": "292000",
        "clip_count": 13,
        "top_topics": [
            "Yu-Gi-Oh!",
            "Cuphead",
            "shorts"
        ]
    },
    "momoa": {
        "id": "UCPcsJUA8PhOy3c4y1Q1eu3g",
        "name": "ikoi momoaCh.\u61a9\u5c45\u3082\u3082\u3042 / \u30d7\u30ed\u30d7\u30ed",
        "english_name": "Ikoi Momoa",
        "type": "vtuber",
        "org": "ProPro",
        "group": null,
        "photo": "https://yt3.ggpht.com/LTCDJSQQXlKJ54iDFFjhl0UcVVtGPcQQqiKckl0mIsTJzl1_-tdHsXwxrkwEngm6JYB1jvkQlg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ikoimomoa",
        "video_count": "112",
        "subscriber_count": "10900",
        "clip_count": 2,
        "top_topics": [
            "singing",
            "apex",
            "asmr"
        ]
    },
    "shumi": {
        "id": "UC1AwHzhQtt6sOJND9MVe0zg",
        "name": "Shumi Shumikea",
        "english_name": "Shumi Shumikea",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/RCNuctmxIVNBJeHYKXg7Q7zYvTOyC5opAML90N672jCj3MnGlxJ74C1sZBX_0D3oO-uh0gs0WQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Shumikea_kea",
        "video_count": "77",
        "subscriber_count": "101000",
        "clip_count": null,
        "top_topics": [
            "drawing",
            "Birthday",
            "shorts"
        ]
    },
    "kanna": {
        "id": "UChEe0jSUdb2ox9wQFYkByEQ",
        "name": "Kanna Tamachi Ch.",
        "english_name": "Kanna Tamachi",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRIBkxRnRqoB-a6AS-flZgn3VCZijRfiATdAY9T=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kannatamachi",
        "video_count": "94",
        "subscriber_count": "146000",
        "clip_count": 272,
        "top_topics": [
            "minecraft",
            "talk",
            "Superchat_Reading"
        ]
    },
    "raima": {
        "id": "UCiQGM9nz7JMp14HJtSVwmkQ",
        "name": "Safiyya Raima Ch.",
        "english_name": "Safiyya Raima",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/H_5fRga-QAONuT-pXTiEHVeuT-tlBb0QzpO-4Y20_fmn1906F796FdvnHPIrj9HuGHaC2q26gw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "SafiyyaRaima",
        "video_count": "191",
        "subscriber_count": "51200",
        "clip_count": 3,
        "top_topics": [
            "Blue_Archive",
            "dbd",
            "Rainbow_Six"
        ]
    },
    "lapis": {
        "id": "UCFGc2HPJyNXCa61ipu2Tnbw",
        "name": "\u5929\u7460\u7483\u3061\u3047\u308d / Lapis Cielo",
        "english_name": "Lapis Cielo",
        "type": "vtuber",
        "org": "Independents",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSWC8jZ8qgmY0FFHuJm4vvKmL2ti6i3ohXX1mXt=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Lapis_Cielo",
        "video_count": "547",
        "subscriber_count": "42500",
        "clip_count": null,
        "top_topics": [
            "among_us",
            "smash",
            "singing"
        ]
    },
    "akato": {
        "id": "UC37QJeT8wHMoZlMasJ7CEbw",
        "name": "\u9752\u9053\u30a2\u30ab\u30c8 / \u3042\u304a\u3042\u304b\u5b66\u5712\u653e\u9001",
        "english_name": "Aomichi Akato",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/p_KZwci3Fr3U8W7TQbYBikpiHaZO7GYGJE27VO7L7ierPczMDFYFECPesA9JeEEwZ1QP48g6V3E=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "aomichi_akato",
        "video_count": "244",
        "subscriber_count": "8060",
        "clip_count": null,
        "top_topics": [
            "talk",
            "shorts",
            "Pokemon"
        ]
    },
    "merry": {
        "id": "UCju7v8SkoWUQ5ITCQwmYpYg",
        "name": "\u30e1\u30ea\u30fc\u30df\u30eb\u30af\u306e\u68ee",
        "english_name": "Merry Milk",
        "type": "vtuber",
        "org": ".LIVE",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQmMysqe9fdIyyxWG99jaKcfF4aSvSKAuWYCXMn3g=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "milk_merry_",
        "video_count": "96",
        "subscriber_count": "72400",
        "clip_count": 9,
        "top_topics": [
            "drawing",
            "minecraft",
            "asmr"
        ]
    },
    "gurin": {
        "id": "UCgA2jKRkqpY_8eysPUs8sjw",
        "name": "Petra Gurin\u3010NIJISANJI EN\u3011",
        "english_name": "Petra Gurin",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/kBgInADvG3nt08S2796EVhXhN9SqUPdNir2jCSfioymem3qdnQqw1YSqOwgWa5UPFOoCY44n=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Petra_Gurin",
        "video_count": "170",
        "subscriber_count": "245000",
        "clip_count": 769,
        "top_topics": [
            "membersonly",
            "talk",
            "Phasmophobia"
        ]
    },
    "monoe": {
        "id": "UCaFhsCKSSS821N-EcWmPkUQ",
        "name": "\u78c1\u5bcc\u30e2\u30ce\u30a8-Jitomi Monoe-:\u6d3b\u52d5\u7d42\u4e86",
        "english_name": "Jitomi Monoe",
        "type": "vtuber",
        "org": "VOMS",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR03BfVW2b-w7-8GoDkUo0kmozAzzOX9EYZcJQm=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Jitomi_Monoe",
        "video_count": "0",
        "subscriber_count": "102000",
        "clip_count": 42,
        "top_topics": [
            "talk",
            "singing",
            "minecraft"
        ]
    },
    "shien": {
        "id": "UChSvpZYRPh0FvG4SJGSga3g",
        "name": "Shien Ch.\u5f71\u5c71\u30b7\u30a8\u30f3",
        "english_name": "Kageyama Shien",
        "type": "vtuber",
        "org": "Hololive",
        "group": "Holostars 3rd Gen",
        "photo": "https://yt3.ggpht.com/N20GfJbuG8BBl9CexUek023y2DXQAYqgYoRRqsVGAYoS-gZsGuH7W1Il0y-8TnIul19rBPG78Jo=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kageyamashien",
        "video_count": "391",
        "subscriber_count": "188000",
        "clip_count": 263,
        "top_topics": [
            "talk",
            "Twisted_Wonderland",
            "apex"
        ]
    },
    "rekui": {
        "id": "UC36tM-mb6ve_OA3jPynxp7g",
        "name": "Emly Ch. \u82d3\u5403\u30a8\u30e0\u30ea\u30a3",
        "english_name": "Rekui Emurii",
        "type": "vtuber",
        "org": "GuildCQ",
        "group": null,
        "photo": "https://yt3.ggpht.com/HRHMo7vsEUtCPjYKT7-hgA9jFjId-TnOHVVkDyozTG4ocaBvYZeAQhUOmWmOM5zwY5rfZxmBFQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "REKUIEMURII",
        "video_count": "209",
        "subscriber_count": "6630",
        "clip_count": 1,
        "top_topics": [
            "singing",
            "Shadowverse",
            "apex"
        ]
    },
    "asumi": {
        "id": "UCF_U2GCKHvDz52jWdizppIA",
        "name": "\u7a7a\u6f84\u30bb\u30ca -Asumi Sena-",
        "english_name": "Asumi Sena",
        "type": "vtuber",
        "org": "VSpo",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLStDDDwZwo4DiRIi-rpHkHPD-rcuZQOHdR5yklB=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "sena_asumi",
        "video_count": "293",
        "subscriber_count": "104000",
        "clip_count": 68,
        "top_topics": [
            "apex",
            "morning",
            "talk"
        ]
    },
    "carro": {
        "id": "UCMzxQ58QL4NNbWghGymtHvw",
        "name": "\u30ab\u30eb\u30ed\u30fb\u30d4\u30ce",
        "english_name": "Carro Pino",
        "type": "vtuber",
        "org": ".LIVE",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSF3qfUNE09Fo1R2k8dsi1rcNfb7hGES5WLsd9cqQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "carro_pino",
        "video_count": "489",
        "subscriber_count": "84400",
        "clip_count": 70,
        "top_topics": [
            "talk",
            "Pokemon",
            "monhun"
        ]
    },
    "pocha": {
        "id": "UCIFQuGbLFsSduuFpALAj1sw",
        "name": "\u72ac\u4e38 \u307d\u3061\u3083 / Inumaru Pocha",
        "english_name": "Inumaru Pocha",
        "type": "vtuber",
        "org": "MAHA5",
        "group": "JP \u5019\u88dc\u751f",
        "photo": "https://yt3.ggpht.com/UA8liAP5AMqbLZcRsFiBqZbXEfJN3QQpku2s2SN9V83N2REiTSyW5TrCXMmrfXGFaedL0RnV3Q=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "inumaru_pocha",
        "video_count": "64",
        "subscriber_count": "1570",
        "clip_count": null,
        "top_topics": [
            "shorts",
            "drawing",
            "minecraft"
        ]
    },
    "tonya": {
        "id": "UCCA4jJY5PrTfIFJIcbI_NhQ",
        "name": "Shabel Tonya Ch",
        "english_name": "Shabel Tonya",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/LRC7yx0Uu77BZl2WNpGbJOrk0dDfWcWNRCzB0iFCjOpnxEm_vr-OkquJ7v6ztA90PxfDbksUOA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ShabelTonya",
        "video_count": "87",
        "subscriber_count": "41800",
        "clip_count": null,
        "top_topics": [
            "minecraft",
            "talk",
            "Stardew_Valley"
        ]
    },
    "fauna": {
        "id": "UCO_aKKYxn4tvrqPjcTzZ6EQ",
        "name": "Ceres Fauna Ch. hololive-EN",
        "english_name": "Ceres Fauna",
        "type": "vtuber",
        "org": "Hololive",
        "group": "English (Council)",
        "photo": "https://yt3.ggpht.com/1rUoSkwh5LJbR8ez3-l02cdoOIKt9IlhKJxkBTqoff2qZb-VV3wUTFpkE2cNDQnOjk8wR-TW=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ceresfauna",
        "video_count": "111",
        "subscriber_count": "476000",
        "clip_count": 1580,
        "top_topics": [
            "asmr",
            "membersonly",
            "minecraft"
        ]
    },
    "horai": {
        "id": "UCCR7I9mNt6Q-VxL5BDknDMg",
        "name": "\u5f15\u304d\u3053\u3082\u308a\u4e45\u9060\u3061\u3083\u3093\u5bdd\u308b",
        "english_name": "Horai Kuon",
        "type": "vtuber",
        "org": "Emoechi",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRcKR7Aqj9L2Bdhg9LEO3siEPabCvsHTTdpFbQf=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kuon_emoechi",
        "video_count": "127",
        "subscriber_count": "17500",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "talk",
            "Music_Cover"
        ]
    },
    "tenma": {
        "id": "UC3K7pmiHsNSx1y0tdx2bbCw",
        "name": "Tenma Ch. \u30de\u30a8\u30df \u5929\u6e80",
        "english_name": "Tenma Maemi",
        "type": "vtuber",
        "org": "Phase Connect",
        "group": null,
        "photo": "https://yt3.ggpht.com/ab1p41f1quWyteO6IHaYVEXpZHsoftG3TejXnFEffSGndsOI5vCYGIWPG6-Rj7dtxLLWOGrd9g=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "tenmamaemi",
        "video_count": "201",
        "subscriber_count": "25600",
        "clip_count": 118,
        "top_topics": [
            "undertale",
            "watchalong",
            "shorts"
        ]
    },
    "mikan": {
        "id": "UC1JuhRTsFgZvi2ie2dTUxbg",
        "name": "\u307f\u304b\u3093\u3068\u30dc\u30fc\u30ab\u30eb\u30ce\u30fc\u30c8",
        "english_name": "Mikan and the VocalNote",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQSAltCSXPnXklzkYX8GkU6wt8SJ3ICEenB5IM=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "mikannovocal",
        "video_count": "186",
        "subscriber_count": "27500",
        "clip_count": null,
        "top_topics": [
            "Music_Cover",
            "singing",
            "totsu"
        ]
    },
    "miria": {
        "id": "UCWq6L3iY4hGK4LmylvQtj-g",
        "name": "\u685c\u6a39\u307f\u308a\u3042",
        "english_name": "Sakuragi Miria",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/LjRrORgfwIDpqbhlJH5k-ZOFojGUwdjk9Sym17ITMnrUIxek3fRs5MHzbI0N-RzJdAJr4vNTh3k=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Miria_Sakuragi_",
        "video_count": "472",
        "subscriber_count": "43100",
        "clip_count": 2,
        "top_topics": [
            "asmr",
            "singing",
            "apex"
        ]
    },
    "reiny": {
        "id": "UC_HrzgYmapddmGSfn2UMHTA",
        "name": "\u304a\u3058\u304d\u3061\u3083\u3093&Reiny Ch.",
        "english_name": "Ojiki-chan & Reiny Channel",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/bzVXYRdnBGEIeIcNFLrrakRdqrjEVm2rdrrNRgZI-nxKmn2IAATNUj2xae7_TvV1zKXssn0E3s0=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Reiny_ENVT // Ojiki: ",
        "video_count": "924",
        "subscriber_count": "22700",
        "clip_count": 14,
        "top_topics": [
            "apex",
            "talk",
            "VRChat"
        ]
    },
    "meiro": {
        "id": "UCLNbNiqFAuNMj1DH1EBrY-g",
        "name": "Meiro ch.\u671d\u65e5\u5948\u3081\u3044\u308d",
        "english_name": "Asahina Meiro",
        "type": "vtuber",
        "org": "Emoechi",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS3Ujqbt2xFARZa13EfnvachUMVVAtTbh5yPc3n=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "56",
        "subscriber_count": "63200",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "minecraft",
            "little_nightmares"
        ]
    },
    "purin": {
        "id": "UCdrQWcX7XLDSUEh9SAxnyBg",
        "name": "Purin ch. \u72ac\u990a\u30d7\u30ea\u30f3 \u3010Tsunderia\u3011",
        "english_name": "Inukai Purin",
        "type": "vtuber",
        "org": "Tsunderia",
        "group": "Generation 0",
        "photo": "https://yt3.ggpht.com/scPqSFZbPBJpzkhEXUq8Hd4yCrfnzW4lEDmAzA9APCj8pthB03JpfOpoyKsoxi6khvry1N6pYw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "inukaipurin",
        "video_count": "168",
        "subscriber_count": "31900",
        "clip_count": 7,
        "top_topics": [
            "Yakuza",
            "talk",
            "minecraft"
        ]
    },
    "asuma": {
        "id": "UCSc_KzY_9WYAx9LghggjVRA",
        "name": "Yugo Asuma \u3010NIJISANJI EN\u3011",
        "english_name": "Yugo Asuma",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/SU0GdLniYhiM9o7g9SAFV53plTjlBsm-rUkVNtYL6qLapS1ziYbgKkNL_gp1cl1-AVOhhMq9ZA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Yugo_Asuma",
        "video_count": "13",
        "subscriber_count": "97900",
        "clip_count": 21,
        "top_topics": [
            "celebration",
            "Devil_may_cry",
            "shorts"
        ]
    },
    "ryugu": {
        "id": "UCu-J8uIXuLZh16gG-cT1naw",
        "name": "Finana Ryugu \u3010NIJISANJI EN\u3011",
        "english_name": "Finana Ryugu",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji EN",
        "photo": "https://yt3.ggpht.com/9t-Lhqhz6BkX_-yblFVwvbhlvobb8WuVBLCF30xxJBrBItkvg30Bt0MQ6wU3ih4oRS9yi8k0Ob4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "FinanaRyugu",
        "video_count": "243",
        "subscriber_count": "265000",
        "clip_count": 890,
        "top_topics": [
            "genshin",
            "Honkai_Impact_3rd",
            "minecraft"
        ]
    },
    "aadya": {
        "id": "UC_aB_-PHLFHiP61djM0oOiQ",
        "name": "Aadya\u3010NIJISANJI IN\u3011",
        "english_name": "Aadya",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS3t241JXQ2rMpNg5D1buHnnQqOpBWn0-ke3fks=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Aadya_VTuber",
        "video_count": "177",
        "subscriber_count": "15100",
        "clip_count": 3,
        "top_topics": [
            "valorant",
            "ark",
            "talk"
        ]
    },
    "canal": {
        "id": "UCBsPgdseCIJXKr1i2flmWYQ",
        "name": "Canal Ch.\u6c34\u4e45\u826f\u304b\u306a\u308b",
        "english_name": "Mizukura Canal",
        "type": "vtuber",
        "org": "Axel-V",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/pk2AtR0O8JRYipZhvKIGQNPS2CVKwahcARcjuRo68D8b1qHIaS2Ryv3oJMfKdN8qxKZRepNLZQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Canal_Mizukura",
        "video_count": "17",
        "subscriber_count": "6400",
        "clip_count": null,
        "top_topics": [
            "among_us",
            "Mahjong",
            "Super Mario"
        ]
    },
    "chima": {
        "id": "UCo7TRj3cS-f_1D9ZDmuTsjw",
        "name": "\u753a\u7530\u3061\u307e\u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Machita Chima",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "SEEDS",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTpaFhkNBf2kkuap4nxvCUFDUJJAX2LwyGNjgc59w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "chima_machita23",
        "video_count": "340",
        "subscriber_count": "470000",
        "clip_count": 178,
        "top_topics": [
            "singing",
            "minecraft",
            "Music_Cover"
        ]
    },
    "hisui": {
        "id": "UCRqBKoKuX30ruKAq05pCeRQ",
        "name": "\u5317\u5c0f\u8def\u30d2\u30b9\u30a4 / Kitakoji Hisui \u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Kitakoji Hisui",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2020",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR2pcvWZzMTnetVhQ-WamkQEMtL_TtFoI8aoOI4=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Hisui_Kitakoji",
        "video_count": "371",
        "subscriber_count": "191000",
        "clip_count": 141,
        "top_topics": [
            "minecraft",
            "talk",
            "singing"
        ]
    },
    "kaina": {
        "id": "UCN7sEdAjj4Q--al9pDsCPOg",
        "name": "\u7070\u540d Kaina ch.",
        "english_name": "Kaina",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/YKq3c-bCT8unR5mJejM_S6aHJmjU1oRdItwqROvvVvqLUOfQekPw1CIfJoN7_QB0IcAdHUgNFw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kaina0124",
        "video_count": "308",
        "subscriber_count": "56500",
        "clip_count": null,
        "top_topics": [
            "Marshmallow",
            "Detroit:_Become_Human",
            "Final_fantasy"
        ]
    },
    "mirei": {
        "id": "UCeShTCVgZyq2lsBW9QwIJcw",
        "name": "\u30103\u5e740\u7d44\u3011\u90e1\u9053\u7f8e\u73b2\u306e\u6559\u5ba4",
        "english_name": "Gundo Mirei",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSdNE-T9cYM194hv2Xfs7jI-mpjsWH2aDOKOr-4tQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "g9v9g_mirei",
        "video_count": "1071",
        "subscriber_count": "399000",
        "clip_count": 414,
        "top_topics": [
            "Mahjong",
            "apex",
            "genshin"
        ]
    },
    "aruma": {
        "id": "UCD1QOCJIAPsMKMvRSXjLahw",
        "name": "Aruma Ch. \u85ac\u888b\u30a2\u30eb\u30de",
        "english_name": "Minai Aruma",
        "type": "vtuber",
        "org": "GuildCQ",
        "group": null,
        "photo": "https://yt3.ggpht.com/RBP6XuW15gdNt2iivDcAfdV2VYP95D8eUHA8a0Apfevx4zJu_uFmsK6KxpyaJcHXC3GUxdxA9so=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "MinaiAlma",
        "video_count": "48",
        "subscriber_count": "3310",
        "clip_count": null,
        "top_topics": [
            "talk",
            "singing",
            "Hollow_Knight"
        ]
    },
    "obake": {
        "id": "UCZIFph1bYyGxmFkkNNLAKdA",
        "name": "Obake PAM Ch.",
        "english_name": "Obake PAM",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/FlbqAWrn9OdE4QcjGweQkH_2WUf2kCAQPbiC4dVvt6ItUWE4pLnUrs9MJ3-KtyeULY0hGe-VPio=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "obakepam",
        "video_count": "449",
        "subscriber_count": "11300",
        "clip_count": null,
        "top_topics": [
            "singing",
            "Music_Cover",
            "Pokemon"
        ]
    },
    "shojo": {
        "id": "UCLhUvJ_wO9hOvv_yYENu4fQ",
        "name": "Siro Channel",
        "english_name": "Dennou Shojo Shiro",
        "type": "vtuber",
        "org": ".LIVE",
        "group": null,
        "photo": "https://yt3.ggpht.com/T1zQF2H4KDqOpYdqoGtcMlWeZ_qpmvGTp1zNA-dQ-tQJxzxp0choIM9mr7dNB-LLaCev5_pGGE0=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "SIROyoutuber",
        "video_count": "1226",
        "subscriber_count": "675000",
        "clip_count": 79,
        "top_topics": [
            "minecraft",
            "apex",
            "pubg"
        ]
    },
    "yuika": {
        "id": "UC_4tXjqecqox5Uc05ncxpxg",
        "name": "\u690e\u540d\u552f\u83ef / Shiina Yuika",
        "english_name": "Shiina Yuika",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Gamers",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSIXjxaKvTQAgBpIwZtdI_Ux_cUEi3wefTTSVZW1w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "yuika_siina",
        "video_count": "1049",
        "subscriber_count": "561000",
        "clip_count": 1104,
        "top_topics": [
            "apex",
            "minecraft",
            "talk"
        ]
    },
    "luxia": {
        "id": "UCRG5fX1v4b-3sUyKfAAtxxA",
        "name": "Luxia Ch.",
        "english_name": "Luxia",
        "type": "vtuber",
        "org": "Polygon",
        "group": null,
        "photo": "https://yt3.ggpht.com/Ep-FBlTkEcjuFAU9GtNR-aQTprYQsSFlEcsiRCr0Rbs-0IzJ84npy2bsLxoNwS_QBLeeq73Uvw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "PolygonLuxia",
        "video_count": "344",
        "subscriber_count": "40800",
        "clip_count": null,
        "top_topics": [
            "talk",
            "minecraft",
            "drawing"
        ]
    },
    "karin": {
        "id": "UC2L0MeiLsfJf4rH_xTVteDw",
        "name": "\u2661Necoma Ch.\u2661\u732b\u7fbd\u304b\u308a\u3093 \u3010\u3051\u3082\u308a\u3075\u3011",
        "english_name": "Nekoma Karin",
        "type": "vtuber",
        "org": "KEMOMIMI REFLE",
        "group": null,
        "photo": "https://yt3.ggpht.com/Yvf_hY5jqgmpOP8pxfr7oxwwT7VDFARHnL4Wi7tXuRd7flHYYRUocB4iXn-6DJyR4EHXehoyqfw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "necoma_kemo",
        "video_count": "104",
        "subscriber_count": "109000",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "zelda",
            "singing"
        ]
    },
    "marin": {
        "id": "UCfipDDn7wY-C-SoUChgxCQQ",
        "name": "\u8449\u5c71\u821e\u9234 / Hayama Marin",
        "english_name": "Hayama Marin",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQPskjnn0-p_VgERL9vHmxTbsX6vnD7hoFM3TXwPg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Hayama_Marin",
        "video_count": "294",
        "subscriber_count": "226000",
        "clip_count": 167,
        "top_topics": [
            "apex",
            "Shadowverse",
            "minecraft"
        ]
    },
    "mutou": {
        "id": "UCteBATr7WkAF2sjWzBJ2hQw",
        "name": "\u7121\u7cd6\u3057\u304a / Mutou Shio",
        "english_name": "Mutou Shio",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/h9xa-NOn4OWO4grMoEq-L6nCUZkQHemxSirJfmN80Fsr-aBYNiIf5k3n5IzWQhXLj-LWSZmncg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "mutou_shio",
        "video_count": "244",
        "subscriber_count": "68400",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "shorts",
            "Music_Cover"
        ]
    },
    "tomoe": {
        "id": "UCuvk5PilcvDECU7dDZhQiEw",
        "name": "\u767d\u96ea \u5df4/Shirayuki Tomoe",
        "english_name": "Shirayuki Tomoe",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/s5fPeGoPZQH3shTefPn7Y53n5iswwF8w_sqAFfwX6Id-HjJMAL03_0M5BWj4--afvB6nGLQU1A=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Tomoe_Shirayuki",
        "video_count": "636",
        "subscriber_count": "352000",
        "clip_count": 304,
        "top_topics": [
            "talk",
            "Splatoon",
            "residentevil"
        ]
    },
    "polka": {
        "id": "UCK9V2B22uJYu3N7eR_BT9QA",
        "name": "Polka Ch. \u5c3e\u4e38\u30dd\u30eb\u30ab",
        "english_name": "Omaru Polka",
        "type": "vtuber",
        "org": "Hololive",
        "group": "5th Generation",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQI_iYxOpfP8bJklQ_VnS4a9jdrwRRlre_JP1Yp=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "omarupolka",
        "video_count": "598",
        "subscriber_count": "938000",
        "clip_count": 3832,
        "top_topics": [
            "minecraft",
            "singing",
            "ark"
        ]
    },
    "civia": {
        "id": "UCgNVXGlZIFK96XdEY20sVjg",
        "name": "Civia Ch.",
        "english_name": "Civia",
        "type": "vtuber",
        "org": "Hololive",
        "group": "INACTIVE",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLQ9BLV8NALtc_u9Ksfpr6aU1PVhe7bPk2hWiaFd=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Civia_Hololive",
        "video_count": "0",
        "subscriber_count": "56500",
        "clip_count": 10,
        "top_topics": [
            "Super Mario"
        ]
    },
    "azuma": {
        "id": "UCARI2g7r-PHaxrIcAYsMfmA",
        "name": "\u30a2\u30ba\u30de\u30ea\u30e0 Azumalim",
        "english_name": "Azuma Lim",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/GpXbNm1J8RD4HF7zDZEM2mGMZZkV-Q0EV4T8vth1_esRrTSDRaN4mPOy35AXoXU7e44VWvVb=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "azuma_lim",
        "video_count": "123",
        "subscriber_count": "215000",
        "clip_count": 8,
        "top_topics": [
            "Music_Cover",
            "announce",
            "shorts"
        ]
    },
    "layla": {
        "id": "UCyRkQSuhJILuGOuXk10voPg",
        "name": "Layla Alstroemeria [NIJISANJI ID]",
        "english_name": "Layla Alstroemeria",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji ID",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTZr0nNCdIAtmpuLo5JiEKgCIDtOy5YYL9Nc2xC=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "LaylaAlstro2434",
        "video_count": "430",
        "subscriber_count": "54200",
        "clip_count": 65,
        "top_topics": [
            "Duolingo",
            "talk",
            "Jump_King"
        ]
    },
    "berry": {
        "id": "UC7A7bGRVdIwo93nqnA3x-OQ",
        "name": "\u82fa\u54b2\u3079\u308a\u3043 / Maisaki Berry",
        "english_name": "Maisaki Berry",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/hv7dfepExXmelYtqOeFp4hGu8kgewYZTT23M4Pn4A8H7fKzFgaS4ld_9JNOfyQgywe_TNw04Sg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "MaisakiBerry",
        "video_count": "322",
        "subscriber_count": "55900",
        "clip_count": 18,
        "top_topics": [
            "singing",
            "talk",
            "asmr"
        ]
    },
    "iroas": {
        "id": "UCgmFrRcyH7d1zR9sIVQhFow",
        "name": "\u30ed\u30fc\u30ec\u30f3\u30fb\u30a4\u30ed\u30a2\u30b9 / Lauren Iroas\u3010\u306b\u3058\u3055\u3093\u3058\u3011",
        "english_name": "Lauren Iroas",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2021",
        "photo": "https://yt3.ggpht.com/_lIzJVcUyL0toipUhCD3tKArOsRr35fkWdAUTfukE9avQg9CMjuRgxRW3XratI4rkhgF4I31Vg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "@Lauren_iroas",
        "video_count": "187",
        "subscriber_count": "277000",
        "clip_count": 173,
        "top_topics": [
            "apex",
            "valorant",
            "ark"
        ]
    },
    "reine": {
        "id": "UChgTyjG-pdNvxxhdsXfHQ5Q",
        "name": "Pavolia Reine Ch. hololive-ID",
        "english_name": "Pavolia Reine",
        "type": "vtuber",
        "org": "Hololive",
        "group": "Indonesia 2nd Gen",
        "photo": "https://yt3.ggpht.com/sG7Yb1kCxTEP9ft03E-1Tr2Rho8GhMZ0_Kq9rf5MojWY9pf8vSL24xPG8e8GaE4jOxQKYlSvaL0=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "pavoliareine",
        "video_count": "456",
        "subscriber_count": "562000",
        "clip_count": 2196,
        "top_topics": [
            "minecraft",
            "talk",
            "apex"
        ]
    },
    "koaku": {
        "id": "UCjlO0QCarccGehZqm6rhnSQ",
        "name": "Mayo Ch.\u604b\u60e1 \u307e\u3088",
        "english_name": "Koaku Mayo",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/5DX8quP7PMLOM1SE9E8c4St1BP13kIWITMhBIBp68IIh1gmW12b_wrcGjYG5scokN7xncNCv=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "koaku_mayo",
        "video_count": "208",
        "subscriber_count": "34200",
        "clip_count": 2,
        "top_topics": [
            "monhun",
            "Pokemon",
            "zelda"
        ]
    },
    "yuuhi": {
        "id": "UC48jH1ul-6HOrcSSfoR02fQ",
        "name": "Yuhi Riri Official",
        "english_name": "Yuuhi Riri",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2",
        "photo": "https://yt3.ggpht.com/A5StWULqnF31ZbuUOD5XVuVLMa6mCxWLrhhOA1MPpI1M9CF0HFrjuPY5jx-OUPuvADsFTnkdvw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Yuuhi_Riri",
        "video_count": "599",
        "subscriber_count": "218000",
        "clip_count": 94,
        "top_topics": [
            "dbd",
            "talk",
            "apex"
        ]
    },
    "dazey": {
        "id": "dazeystray",
        "name": "dazey",
        "photo": "https://pbs.twimg.com/profile_images/1497187619653885955/3vvrA03k_400x400.jpg"
    },
    "isumi": {
        "id": "UCIClNlvEUvEqLe_rs_i8h_w",
        "name": "sakura isumiCh.\u6714\u685c \u8863\u6f84 / \u30d7\u30ed\u30d7\u30ed",
        "english_name": "Sakura Isumi",
        "type": "vtuber",
        "org": "ProPro",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLR_L8_Flv_at5fyVD6SDTw5iwUAoB7M4I9wkrpT=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "sakuraisumi",
        "video_count": "207",
        "subscriber_count": "13800",
        "clip_count": 10,
        "top_topics": [
            "singing",
            "minecraft",
            "Mahjong"
        ]
    },
    "ceres": {
        "id": "UCO_aKKYxn4tvrqPjcTzZ6EQ",
        "name": "Ceres Fauna Ch. hololive-EN",
        "english_name": "Ceres Fauna",
        "type": "vtuber",
        "org": "Hololive",
        "group": "English (Council)",
        "photo": "https://yt3.ggpht.com/1rUoSkwh5LJbR8ez3-l02cdoOIKt9IlhKJxkBTqoff2qZb-VV3wUTFpkE2cNDQnOjk8wR-TW=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ceresfauna",
        "video_count": "111",
        "subscriber_count": "476000",
        "clip_count": 1580,
        "top_topics": [
            "asmr",
            "membersonly",
            "minecraft"
        ]
    },
    "raise": {
        "id": "UCrFM9PePa4a7654qY9psGjA",
        "name": "\u8086\u30ce\u5cf6\u4f86\u4e16 / Shinoshima Raise",
        "english_name": "Shinoshima Raise",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/N7dYBRxK0GW66erUUq3KYVPEy4Mf8HeGaL9B_Yd8Swn2QUs2MKb8uShLBJGmawtmqXi-JGxp8A=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "r_snsm",
        "video_count": "28",
        "subscriber_count": "57100",
        "clip_count": null,
        "top_topics": [
            "asmr",
            "Needy_Streamer_Overload",
            "Music_Cover"
        ]
    },
    "mirai": {
        "id": "UCMYtONm441rBogWK_xPH9HA",
        "name": "Mirai Akari Project",
        "english_name": "Mirai Akari",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS09Ph0eazxNGOTp7NE2VVbeF_0vHYcjW5UOCRraA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "MiraiAkari_prj",
        "video_count": "475",
        "subscriber_count": "711000",
        "clip_count": 12,
        "top_topics": [
            "Original_Song",
            "Music_Cover",
            "asmr"
        ]
    },
    "ringo": {
        "id": "UCf57-IJn5mUJDyqd9uNEmrg",
        "name": "\u4e5d\u6761 \u6797\u6a8e\u3010Kujo Ringo Official\u3011",
        "english_name": "Kujo Ringo",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRSbrN_85qZLa7pI530I5j7Aw9qAvKq47ooidkh=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ringo_0_0_5",
        "video_count": "286",
        "subscriber_count": "28100",
        "clip_count": 3,
        "top_topics": [
            "Animal_Crossing",
            "3D_Stream",
            "TRPG"
        ]
    },
    "miori": {
        "id": "UCyKsg-57XC9pyHbP7v3kCPQ",
        "name": "Miori Ch. \u30df\u30aa\u30ea\u30fb\u30bb\u30ec\u30b9\u30bf \u3010Tsunderia\u3011",
        "english_name": "Miori Celesta",
        "type": "vtuber",
        "org": "Tsunderia",
        "group": "Generation 1",
        "photo": "https://yt3.ggpht.com/wWe-Gu7jH1gpJJFR4n_9LWVeC3ohD_IAPtyXvEaEqmfbNh7IjGrSdzkiKpEnQn6UIQYrEeZZhg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "mioricelesta",
        "video_count": "256",
        "subscriber_count": "172000",
        "clip_count": 29,
        "top_topics": [
            "Music_Cover",
            "singing",
            "Fallout"
        ]
    },
    "petit": {
        "id": "UCIG9rDtgR45VCZmYnd-4DUw",
        "name": "\u30e9\u30c8\u30ca\u30fb\u30d7\u30c6\u30a3 -Ratna Petit -\u306b\u3058\u3055\u3093\u3058\u6240\u5c5e",
        "english_name": "Ratna Petit",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSn6_wJR3tRDL5cStXSUO2ubhfAQe3PgX2r_zXhXQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ratna_petit",
        "video_count": "716",
        "subscriber_count": "368000",
        "clip_count": 367,
        "top_topics": [
            "apex",
            "asmr",
            "minecraft"
        ]
    },
    "ratna": {
        "id": "UCIG9rDtgR45VCZmYnd-4DUw",
        "name": "\u30e9\u30c8\u30ca\u30fb\u30d7\u30c6\u30a3 -Ratna Petit -\u306b\u3058\u3055\u3093\u3058\u6240\u5c5e",
        "english_name": "Ratna Petit",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 2019",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSn6_wJR3tRDL5cStXSUO2ubhfAQe3PgX2r_zXhXQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "ratna_petit",
        "video_count": "716",
        "subscriber_count": "368000",
        "clip_count": 367,
        "top_topics": [
            "apex",
            "asmr",
            "minecraft"
        ]
    },
    "urara": {
        "id": "UCGNxKTqNK3GMjgvgUixJroQ",
        "name": "Urara Ch. \u6d77\u725b\u3046\u3089\u3089 \u3010Tsunderia\u3011",
        "english_name": "Umiushi Urara",
        "type": "vtuber",
        "org": "Tsunderia",
        "group": "Generation 0",
        "photo": "https://yt3.ggpht.com/lMhQqFQaUzy1RTvfu7xcYvCYuThhyagEo_iXvq4VSAzU6iuvQHtRnoKsHemlw73vJxCKN2kb-Q=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "umiushiurara",
        "video_count": "53",
        "subscriber_count": "6510",
        "clip_count": 6,
        "top_topics": [
            "talk",
            "Marshmallow",
            "ringfit"
        ]
    },
    "ginga": {
        "id": "UCT1AQFit-Eaj_YQMsfV0RhQ",
        "name": "\u9280\u6cb3\u30a2\u30ea\u30b9\u306e\u5730\u7403\u4fb5\u7565ch.",
        "english_name": "Ginga Alice",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/25uDB9pmeeEkpMwCxVeF1NiGxiAtdYe5jSqKVSaNh_Tvw2vYoWfCHNhBwPbWw0TW4Cyltifzr-k=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "alice_shinryaku",
        "video_count": "396",
        "subscriber_count": "74500",
        "clip_count": 2,
        "top_topics": [
            "Anniversary",
            "Dancing",
            "Original_Song"
        ]
    },
    "laila": {
        "id": "UCFSkExeBcqI4nb_ArHeByNw",
        "name": "\u30b7\u30ce\u30e9\u30a4\u30e9 - Shino Laila\u3010WACTOR\u3011",
        "english_name": "Shino Laila",
        "type": "vtuber",
        "org": "WACTOR",
        "group": null,
        "photo": "https://yt3.ggpht.com/uw7MjV-nnXi57cg_nptGOn7MgA3S2Jq7NiQMJ1zwD6Kmolf33wKMv7vgvOP80ZUywik__qrrRQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "157",
        "subscriber_count": "121000",
        "clip_count": 70,
        "top_topics": [
            "singing",
            "apex",
            "talk"
        ]
    },
    "amaou": {
        "id": "UCUkYFx-HMPcTa7YMWTtD3vw",
        "name": "Banana Amaou Ch.\u7518\u738b\u3070\u306a\u306a",
        "english_name": "Amaou Banana",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/2ERrc07ewwFRFcXaTFj0D-q93z05y-__Itphp42g2HdOUmwUSvrLfgPvACSwBTU-vDLwPwunDg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": null,
        "video_count": "267",
        "subscriber_count": "16500",
        "clip_count": null,
        "top_topics": [
            "minecraft",
            "singing",
            "morning"
        ]
    },
    "saeki": {
        "id": "UCp-8cPCziyRz2OXJWBOjsCQ",
        "name": "\u3055\u3048\u304d\u3084\u3072\u308d\u306e\u304a\u3078\u3084",
        "english_name": "Saeki Yahiro",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/zJPw7RbGI5MdmF8LatihAbsIYuG6AHalSJdXgLYnQxAdg3usKqCeGIe-k8QB30hNmgt4On5ivw=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "saekiyahiro",
        "video_count": "99",
        "subscriber_count": "25000",
        "clip_count": null,
        "top_topics": [
            "drawing",
            "3D_Stream",
            "talk"
        ]
    },
    "miyui": {
        "id": "UC3Z7UaEe_vMoKRz9ABQrI5g",
        "name": "Momonya Ch.\u6843\u5948\u307f\u3086\u3044",
        "english_name": "Momonya Miyui",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLSg5LQX2H2udC8c5yTp3jm6MjA9HfN5jvGR7C8Q=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "MomonyaMiyui",
        "video_count": "342",
        "subscriber_count": "25800",
        "clip_count": 3,
        "top_topics": [
            "asmr",
            "Pokemon",
            "Mahjong"
        ]
    },
    "kanae": {
        "id": "UCspv01oxUFf_MTSipURRhkA",
        "name": "Kanae Channel",
        "english_name": "Kanae",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Gamers",
        "photo": "https://yt3.ggpht.com/dfCsIDvqaK7K1yIzVFGEq5-1cBAdERky6P4qwqcyXFNLVW1cV-rx7J-Gaj6dUSl2ZaRNPdl0gg=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Kanae_2434",
        "video_count": "2413",
        "subscriber_count": "951000",
        "clip_count": 2756,
        "top_topics": [
            "apex",
            "pubg",
            "valorant"
        ]
    },
    "akemi": {
        "id": "UC61iJVuFVS4YsnPkZe5EmXg",
        "name": "Akemi Ch. \u732b\u753a\u30a2\u30b1\u30df",
        "english_name": "Nekomachi Akemi",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/STnsuK1DcTk03CUhdVevkupXytP5pY_rrJs87_d7MJxIkDUkGRj0AE1zjT4Tm7AamNEuyFwLZUM=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "AkemiNekomachi",
        "video_count": "187",
        "subscriber_count": "30600",
        "clip_count": 12,
        "top_topics": [
            "minecraft",
            "apex",
            "talk"
        ]
    },
    "kuuki": {
        "id": "UC0O0-Th0plo8YADhx71eO5g",
        "name": "Kuuki Tsuki \u3010Virtunix\u3011",
        "english_name": "Kuuki Tsuki",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/UgJzz2-VL4TamHnVgLak7tapI0PCqe2b-NokyRVUhOz4RCWS3ZojwCcumeYcl5xuYa-k4cyt=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "kuukivt",
        "video_count": "34",
        "subscriber_count": "43000",
        "clip_count": 17,
        "top_topics": [
            "singing",
            "minecraft",
            "Birthday"
        ]
    },
    "derem": {
        "id": "UCMzVa7B8UEdrvUGsPmSgyjA",
        "name": "Derem Kado \u3010NIJISANJI ID\u3011",
        "english_name": "Derem Kado",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Nijisanji ID",
        "photo": "https://yt3.ggpht.com/7mic8jKhhS14RTu6MYUcMtLyem1b0eFGsdIbnOpKorF6QreHD-LP3Mh3Gg5-k1bs6bPtwPCkng=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "DeremKado",
        "video_count": "319",
        "subscriber_count": "28500",
        "clip_count": 32,
        "top_topics": [
            "minecraft",
            "talk",
            "Final_fantasy"
        ]
    },
    "matoi": {
        "id": "UCFrH3bfn7gDeV29wrHHM80g",
        "name": "\u5c04\u8cab\u307e\u3068\u3044\u3061\u3083\u3093\u306d\u308b",
        "english_name": "Inuki Matoi",
        "type": "vtuber",
        "org": "ReAcT",
        "group": null,
        "photo": "https://yt3.ggpht.com/4wqgVYh6yG7LfUO4j9Xst_rmqFwiqe2xgfVgM9s5LOUqaVc7hGaAXFgct_k41VljrCE78aAVB1Y=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "matoi_inuki",
        "video_count": "552",
        "subscriber_count": "48300",
        "clip_count": 154,
        "top_topics": [
            "asmr",
            "singing",
            "Music_Cover"
        ]
    },
    "uruha": {
        "id": "UCl_gCybOJRIgOXw6Qb4qJzQ",
        "name": "Rushia Ch. \u6f64\u7fbd\u308b\u3057\u3042",
        "english_name": "Uruha Rushia",
        "type": "vtuber",
        "org": "Hololive",
        "group": "3rd Generation",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLS1vIibOCM-xhRmT2qAwQca_hVTz8OnW9c-S9rbJA=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "uruharushia",
        "video_count": "453",
        "subscriber_count": "1610000",
        "clip_count": 8002,
        "top_topics": [
            "minecraft",
            "ark",
            "mariokart"
        ]
    },
    "moira": {
        "id": "UCvmppcdYf4HOv-tFQhHHJMA",
        "name": "\u300a\u306b\u3058\u3055\u3093\u3058\u6240\u5c5e\u306e\u5973\u795e\u300b\u30e2\u30a4\u30e9",
        "english_name": "Moira",
        "type": "vtuber",
        "org": "Nijisanji",
        "group": "Wave 1",
        "photo": "https://yt3.ggpht.com/ytc/AKedOLTKBRtfrpYcNOdBNo6aEgFvKw9FV4C14xBBUpDP=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "Moiramoimoimoi",
        "video_count": "364",
        "subscriber_count": "138000",
        "clip_count": 18,
        "top_topics": [
            "watchalong",
            "talk",
            "Pokemon"
        ]
    },
    "chiem": {
        "id": "UCK-J76mePKSWV4Sl6zV1Eiw",
        "name": "Chiem Ch. \u30c1\u30a8\u30e0\u30c1\u30e3\u30f3\u30cd\u30eb",
        "english_name": "Chiem",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/UcAkmV65BAEcmV5exANVAJzQxJ_MBQJSdsdezCyX90GxQYNamVkHKxzBGYcmdvCuVBSrjg0JPQ=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "_Chiem_",
        "video_count": "159",
        "subscriber_count": "2000",
        "clip_count": null,
        "top_topics": [
            "drawing",
            "watchalong",
            "ddlc"
        ]
    },
    "amrzs": {
        "id": "UCULLc5b5rzDNp9K-rtF8W5w",
        "name": "Amrzs ch. \u963f\u723e\u59ff",
        "english_name": "Amrzs ch. \u963f\u723e\u59ff",
        "type": "vtuber",
        "org": "SquareLive",
        "group": null,
        "photo": "https://yt3.ggpht.com/qtoOWznVFgL1aW9mtaEKqH3Sq0rKSF6hDpp34kvcul-OQCB60EDwb6KcOXAKZrx0dXOugoh2=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "AmrzsMonstar",
        "video_count": "45",
        "subscriber_count": "9260",
        "clip_count": 3,
        "top_topics": [
            "genshin",
            "minecraft",
            "shorts"
        ]
    },
    "amuro": {
        "id": "UCg9_f0eR1KuqAb86Lj7xNew",
        "name": "\u305f\u304f\u307c\u3093\u611f\u6fc0\u3043!\u3010\u65e7\u306d\u3077\u306d\u3077\u3011",
        "english_name": "Amuro / Meguri Ai",
        "type": "vtuber",
        "org": "Independents",
        "group": null,
        "photo": "https://yt3.ggpht.com/ytc/AKedOLRkuxLsDkVRfyh5vELRmaE3_BsCDeELhiMUX-rX0w=s800-c-k-c0x00ffffff-no-rj",
        "twitter": "2525_amuamu",
        "video_count": "425",
        "subscriber_count": "86400",
        "clip_count": null,
        "top_topics": [
            "smash",
            "Gundam_Battle_Operation",
            "Pokemon"
        ]
    }
} as unknown as Record<string, VTuber>;

export const WORDS = Object.keys(VTUBERS);
